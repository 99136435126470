import React, { Component } from 'react'
import styled from '@emotion/styled'
import referencesContent from '../References/Content'
import { ModalContent } from '../ModalUtils'
import { P } from '../../common/Styles'

const Container = styled.div`
  h4 {
    margin: 0.5rem 0;
  }

  a:link,
  a:visited,
  a:active {
    text-decoration: none;
  }

  a:hover {
    text-decoration: underline;
  }

  a:link,
  a:visited,
  a:hover,
  a:active {
    color: black;
  }
`

const Contact = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  padding: 1rem 0;
`

class ReferencesModal extends Component {
  render() {
    const { chapter } = this.props
    const content = referencesContent[chapter]

    return (
      <ModalContent style={{ width: 450 }}>
        <h3>To find out more, contact</h3>
        <Container>
          <Contact>
            {content.name}
            <P style={{ margin: 0 }}>{content.email}</P>
          </Contact>
        </Container>
        {content.reference}
      </ModalContent>
    )
  }
}

export default ReferencesModal
