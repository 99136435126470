import React from 'react'

const Svg87 = props => (
  <svg width={107} height={250} {...props}>
    <path
      d="M0 249h100a6 6 0 006-6V0h0"
      stroke="#122373"
      fill="none"
      fillRule="evenodd"
    />
  </svg>
)

export default Svg87
