import React from 'react'
import styled from '@emotion/styled'

import { H3, P } from '../../common/Styles'
import IconCard from './IconCard'
import CardGrid from './CardGrid'

import v1 from '../Introduction/sharedMobilityIcons/SM v1.0.svg'
import v3 from '../Introduction/sharedMobilityIcons/SM v3.0.svg'
import rideHailing from '../Introduction/sharedMobilityIcons/SM Ride hailing.svg'
import pooling from '../Introduction/sharedMobilityIcons/SM pooling.svg'
import microTransit from '../Introduction/sharedMobilityIcons/SM micro-transit.svg'
import demandResponsive from '../Introduction/sharedMobilityIcons/SM demand responsive.svg'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
`
const Outer = styled.div`
  column-width: 16em;
`
const Text = styled(P)`
  margin: 0;
`

const SharedMobility = () => {
  return (
    <Container>
      <H3 color={'orange'}>Shared Mobility</H3>
      <Outer>
        <Text>
          Shared mobility comprises any form of mobility in which the vehicle is
          not owned by an individual (i.e., fleet operated). Shared mobility
          solutions are shifting consumers’ preferences away from car ownership
          toward newer forms of transportation. The market for shared mobility
          can be shaped in different ways, from the status-quo to more
          beneficial, transformative outcomes.
        </Text>
      </Outer>

      <CardGrid style={{ marginTop: '2.5rem' }}>
        <IconCard
          title="V 1.0"
          body="Vehicles are picked up and either returned to the same location or dropped off in a different location."
          img={v1}
        />
        <IconCard
          title="V 2.0"
          body="Peer-to-peer sharing where individuals can rent out their personal vehicles to others when not in use."
          img={v3}
        />
        <IconCard
          title="Ride hailing"
          body="Platform where individuals can hail and pay for a ride from a professional or part-time driver through an app."
          img={rideHailing}
        />
        <IconCard
          title="Pooling"
          body="Extension of ride-hailing where individuals can be matched in real-time to share rides with others going on similar route."
          img={pooling}
        />
        <IconCard
          title="Demand responsive"
          body="Higher occupancy mobility options that reactively respond to requests for mobility services on the basis of a request from an end-user."
          img={demandResponsive}
        />
        <IconCard
          title="Micro-transit"
          body="Mobility provided by light vehicles such as electric scooters, shared bicycles and electric pedal assisted bicycles."
          img={microTransit}
        />
      </CardGrid>
    </Container>
  )
}

export default SharedMobility
