import React, { memo } from 'react'
import PulseTransition from '../../hocs/PulseTransition'

const ScenarioSvgItem = memo(
  ({ Svg, data, selectedScenario, scenario, isChanged }) => {
    let svgProps = {
      x: `${data.x}px`,
      y: `${data.y}px`,
      scenario,
    }

    if (isChanged) {
      // TODO: reinclude after design review
      // const HOC = PulseTransition(Svg);
      // return <HOC {...svgProps} />;
      return <Svg {...svgProps} />
    }

    // Inactive styling
    svgProps.opacity = 0.3

    return <Svg {...svgProps} />
  }
)

export default ScenarioSvgItem
