import React from 'react'

const SvgRoad = props => (
  <svg width={850} height={22} {...props}>
    <g fill="none" fillRule="evenodd">
      <path fill="#E6EFF2" d="M0 0h850v22H0z" />
      <path
        stroke="#FFF"
        strokeDasharray="15 25"
        strokeLinecap="square"
        strokeWidth={2}
        d="M12 9.5h838"
      />
    </g>
  </svg>
)

export default SvgRoad
