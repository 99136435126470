import React, { PureComponent } from 'react'
import styled from '@emotion/styled'
import { CITYWIDTH } from '../constants/config'

const G = styled.g`
  g {
    opacity: 0;
    animation: city-${props => props.name} ${props =>
  props.time}s linear infinite;
    animation-delay: ${props => props.delay}s;
  }
  @keyframes city-${props => props.name} {
    from {
      opacity: 1;     
      transform: translate3d(${props => props.from}px, 0, 0);
    }
    to {
      opacity: 1;
      transform: translate3d(${props => props.to}px, 0, 0);
    }
  }
`
const Once = styled.g`
  g {
    animation: city-${props => props.name} ${props => props.time}s linear;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;  
  }
  @keyframes city-${props => props.name} {
    from {
      transform: translate3d(${props => props.from}px, 0, 0);
    }
    to {
      transform: translate3d(${props => props.to}px, 0, 0);
    }
  }
`

export default WrappedComponent => {
  class CityRoadTransition extends PureComponent {
    render() {
      const { speed, ...rest } = this.props
      return (
        <g>
          <Once
            name={'one'}
            to={-(CITYWIDTH * 2)}
            from={0}
            time={speed}
            delay={0}
          >
            <WrappedComponent {...rest} />
          </Once>
          <G
            name={'two'}
            to={-CITYWIDTH}
            from={CITYWIDTH}
            time={speed}
            delay={0}
          >
            <WrappedComponent {...rest} />
          </G>
          <G
            name={'three'}
            to={-CITYWIDTH}
            from={CITYWIDTH}
            time={speed}
            delay={speed / 2}
          >
            <WrappedComponent {...rest} />
          </G>
        </g>
      )
    }
  }
  return CityRoadTransition
}
