import React, { Component } from 'react'
import styled from '@emotion/styled'

const G = styled.g`
  transform-origin: center center;
  animation: pulse 0.4s linear forwards;

  @keyframes pulse {
    from {
      transform: scale3d(1, 1, 1);
    }
    50% {
      transform: scale3d(1.01, 1.01, 1.01);
    }
    to {
      transform: scale3d(1, 1, 1);
    }
  }
`

export default WrappedComponent => {
  class PulseTransition extends Component {
    render() {
      const { order = 1, ...rest } = this.props
      return (
        <G delay={(order - 1) * 1}>
          <WrappedComponent {...rest} />
        </G>
      )
    }
  }
  return PulseTransition
}
