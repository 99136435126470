import React, { Component } from 'react'
import styled from '@emotion/styled'

const G = styled.g`
  opacity: 0;
  animation: appear 1s linear forwards;
  animation-delay: ${props => props.delay}s;

  @keyframes appear {
    to {
      opacity: 1;
    }
  }
`

export default WrappedComponent => {
  class AppearTransition extends Component {
    render() {
      const { order, ...rest } = this.props
      return (
        <G delay={(order - 1) * 1}>
          <WrappedComponent {...rest} />
        </G>
      )
    }
  }
  return AppearTransition
}
