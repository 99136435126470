import React from 'react'

const Svg33 = props => (
  <svg width={63} height={74} {...props}>
    <path
      d="M0 1h25.21a3 3 0 013 3v30a3 3 0 003 3H63 0h34.267-3a3 3 0 00-3 3v30s0 0 0 0c0 1.658-1.344 3-3 3L0 72.992"
      stroke="#ED5835"
      fill="none"
      fillRule="evenodd"
    />
  </svg>
)

export default Svg33
