import React, { memo } from 'react'
import LineTransition from '../../hocs/LineTransition'
import AppearTransition from '../../hocs/AppearTransition'

const hocs = {
  reveal: LineTransition,
}

const SvgItem = memo(({ Svg, data, shouldAnimate }) => {
  const x = `${data.x}px`
  const y = `${data.y}px`

  const { animation, order } = data

  if (shouldAnimate) {
    const HOC = (hocs[animation] || AppearTransition)(Svg)
    return <HOC x={x} y={y} order={order} />
  }

  return <Svg x={x} y={y} order={order} />
})

export default SvgItem
