import React from 'react'

const Svg47 = props => (
  <svg width={26} height={8} {...props}>
    <path
      d="M2.4 7.57V1.44H0V.57h5.79v.87h-2.4v6.13H2.4zm4.261-6.32c-.37 0-.65-.27-.65-.62s.28-.63.65-.63.65.27.65.61c0 .36-.27.64-.65.64zm-.48 6.32v-5.3h.96v5.3h-.96zm8.992-5.35c1.29 0 2.17.74 2.17 2.3v3.05h-.96V4.63c0-1.04-.5-1.55-1.37-1.55-.96 0-1.59.61-1.59 1.76v2.73h-.96V4.63c0-1.04-.5-1.55-1.37-1.55-.96 0-1.59.61-1.59 1.76v2.73h-.96v-5.3h.92v.79c.38-.54 1.03-.84 1.83-.84.81 0 1.49.32 1.84.98.4-.6 1.14-.98 2.04-.98zm8.331 2.73c0 .08-.01.2-.02.29h-4.3c.12.93.87 1.55 1.92 1.55.62 0 1.14-.21 1.53-.64l.53.62c-.48.56-1.21.86-2.09.86-1.71 0-2.85-1.13-2.85-2.71 0-1.57 1.13-2.7 2.66-2.7 1.53 0 2.62 1.1 2.62 2.73zm-2.62-1.92c-.92 0-1.6.62-1.7 1.52h3.4c-.1-.89-.77-1.52-1.7-1.52zm3.752.56c-.37 0-.68-.29-.68-.69 0-.4.31-.68.68-.68.36 0 .66.28.66.68s-.3.69-.66.69zm0 4.04c-.37 0-.68-.29-.68-.69 0-.4.31-.68.68-.68.36 0 .66.28.66.68s-.3.69-.66.69z"
      fill="#B77941"
      fillRule="nonzero"
    />
  </svg>
)

export default Svg47
