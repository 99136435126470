import React from 'react'
import styled from '@emotion/styled'

import { H3, P, LI } from '../../common/Styles'
import IconCard from './IconCard'
import BadgeCard from './BadgeCard'
import CardGrid from './CardGrid'
import handsFeetOn from '../Introduction/autonomousMobilityIcons/AV Level 0.svg'
import handsOrFeetOff from '../Introduction/autonomousMobilityIcons/AV Level 1.svg'
import handsAndFeetOff from '../Introduction/autonomousMobilityIcons/AV Level 2.svg'
import handsAndFeetOffL3 from '../Introduction/autonomousMobilityIcons/AV Level 3.svg'
import brainOffL4 from '../Introduction/autonomousMobilityIcons/AV Level 4.svg'
import brainOffL5 from '../Introduction/autonomousMobilityIcons/AV Level 5.svg'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
`

const Outer = styled.div`
  column-width: 16em;
`
const Text = styled(P)`
  margin: 0;
`

const SectionTitle = styled.h4`
  font-size: 1rem;
  font-weight: bold;
  margin: 2.5rem 0 2rem;
`

const AutonomousMobility = () => {
  return (
    <Container>
      <H3 color={'green'}>Autonomous Mobility</H3>
      <Outer>
        <Text>
          Levels of automation increase from no automation (Level 0) to fully
          autonomous (Levels 4+). A fully autonomous vehicle is driven by an AI
          system, rather than by a human driver. Level 4 vehicles will be
          referenced to specific geographies, such as neighbourhoods, highways
          or city boundaries whereas Level 5 vehicles would be able to drive
          anywhere (theoretically), but they are not likely to be available in
          the foreseeable future.
        </Text>
      </Outer>

      <SectionTitle>A self-driving vehicle does three things:</SectionTitle>

      <CardGrid>
        <BadgeCard
          title="Sense"
          body="Gathering information from the external
            environment e.g. lane paint lines and nearby objects using sensors
            (cameras, long and short range radars, ultrasonic and LiDAR)"
        />
        <BadgeCard
          title="Perceive"
          body="Filtering, interpreting and
            understanding sensor data. Predicting human behaviours is a core
            element of this function"
        />
        <BadgeCard
          title="Decide"
          body="Safely choosing the best course of
            actions (changing or maintaining directions, accelerating or
            decelerating) along a tight band of control"
        />
      </CardGrid>

      <SectionTitle>Levels of automation</SectionTitle>

      <CardGrid>
        <IconCard
          title="Level 0:"
          subtitle="Hands and feet on"
          body="No automated functions."
          img={handsFeetOn}
        />
        <IconCard
          title="Level 1:"
          subtitle="Hands or feet off"
          body="Driver assistance functions that control speed or steering."
          img={handsOrFeetOff}
        />
        <IconCard
          title="Level 2:"
          subtitle="Hands and feet off"
          body="Driver assistance functions that control speed or steering."
          img={handsAndFeetOff}
        />
        <IconCard
          title="Level 3:"
          subtitle="Hands and feet off"
          body="Full automation in limited environments with driver control but with driver interventions if necessary."
          img={handsAndFeetOffL3}
        />
        <IconCard
          title="Level 4:"
          subtitle="Brain off"
          body="Full automation in limited environments without driver control or intervention."
          img={brainOffL4}
        />
        <IconCard
          title="Level 5:"
          subtitle="Brain off"
          body="Full driverless automation in all environments."
          img={brainOffL5}
        />
      </CardGrid>
    </Container>
  )
}

export default AutonomousMobility
