import React from 'react'

const Svg15 = props => (
  <svg width={28} height={8} {...props}>
    <path
      d="M5.42 5.82V3.54h.96v2.73c-.7.59-1.67.89-2.65.89C1.58 7.16 0 5.65 0 3.58 0 1.51 1.58 0 3.75 0 4.86 0 5.8.37 6.44 1.08l-.62.62C5.24 1.14 4.58.89 3.79.89 2.17.89 1 2.02 1 3.58c0 1.54 1.17 2.69 2.78 2.69.59 0 1.14-.13 1.64-.45zm7.091-1.36c0 .08-.01.2-.02.29h-4.3c.12.93.87 1.55 1.92 1.55.62 0 1.14-.21 1.53-.64l.53.62c-.48.56-1.21.86-2.09.86-1.71 0-2.85-1.13-2.85-2.71 0-1.57 1.13-2.7 2.66-2.7 1.53 0 2.62 1.1 2.62 2.73zm-2.62-1.92c-.92 0-1.6.62-1.7 1.52h3.4c-.1-.89-.77-1.52-1.7-1.52zm6.352-.81c1.29 0 2.2.74 2.2 2.3v3.05h-.96V4.14c0-1.04-.52-1.55-1.43-1.55-1.02 0-1.68.61-1.68 1.76v2.73h-.96v-5.3h.92v.8c.39-.54 1.07-.85 1.91-.85zm11.503 5.35h-1.15l-2.1-2.89-2.07 2.89h-1.15l2.63-3.59-2.47-3.41h1.14l1.96 2.69 1.94-2.69h1.09l-2.47 3.38 2.65 3.62z"
      fill="#C15727"
      fillRule="nonzero"
    />
  </svg>
)

export default Svg15
