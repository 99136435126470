import React from 'react'

const Svg41 = props => (
  <svg width={26} height={8} {...props}>
    <path
      d="M4.94 1.44H1v2.44h3.51v.86H1v2.83H0v-7h4.94v.87zm1.221-.19c-.37 0-.65-.27-.65-.62s.28-.63.65-.63.65.27.65.61c0 .36-.27.64-.65.64zm-.48 6.32v-5.3h.96v5.3h-.96zm6.832 0h-1.09l-1.53-2.03-1.55 2.03h-1.07l2.09-2.71-1.99-2.59h1.07l1.46 1.91 1.45-1.91h1.05l-2 2.59 2.11 2.71zm5.231-2.62c0 .08-.01.2-.02.29h-4.3c.12.93.87 1.55 1.92 1.55.62 0 1.14-.21 1.53-.64l.53.62c-.48.56-1.21.86-2.09.86-1.71 0-2.85-1.13-2.85-2.71 0-1.57 1.13-2.7 2.66-2.7 1.53 0 2.62 1.1 2.62 2.73zm-2.62-1.92c-.92 0-1.6.62-1.7 1.52h3.4c-.1-.89-.77-1.52-1.7-1.52zM22.686.15h.96v7.42h-.92v-.84c-.43.6-1.11.9-1.89.9-1.55 0-2.68-1.09-2.68-2.71 0-1.62 1.13-2.7 2.68-2.7.75 0 1.41.28 1.85.85V.15zm-1.77 6.64c1.01 0 1.78-.74 1.78-1.87 0-1.13-.77-1.86-1.78-1.86-1.02 0-1.79.73-1.79 1.86 0 1.13.77 1.87 1.79 1.87zm4.351-3.2c-.37 0-.68-.29-.68-.69 0-.4.31-.68.68-.68.36 0 .66.28.66.68s-.3.69-.66.69zm0 4.04c-.37 0-.68-.29-.68-.69 0-.4.31-.68.68-.68.36 0 .66.28.66.68s-.3.69-.66.69z"
      fill="#B77941"
      fillRule="nonzero"
    />
  </svg>
)

export default Svg41
