import React from 'react'
import styled from '@emotion/styled'

import img from './doomsday_freeflow_graphic.svg'

const Svg = styled.img`
  height: 180px;
`
const TakingActionSvg = () => {
  return <Svg src={img} />
}

export default TakingActionSvg
