import React from 'react'
import { motion } from 'framer-motion'
import FramerText from '../hocs/FramerText'
import { makeCalcPill } from '../utils/index'
import { DURATION } from '../constants/config'

const calcPill = makeCalcPill([30, 50, 20])

const Svg20 = ({ scenario, ...rest }) => {
  const {
    lastBottomHeight,
    lastBottomStart,
    lastMiddleHeight,
    lastMiddleStart,
    lastTopHeight,
    lastTopStart,
    bottomHeight,
    bottomStart,
    middleHeight,
    middleStart,
    topHeight,
    topStart,
  } = calcPill(scenario, DURATION)

  return (
    <svg width={32} height={101} {...rest}>
      <defs>
        <rect id="20_svg__a" width={32} height={101} x={0} y={0} rx={16} />
      </defs>
      <g fill="none" fillRule="evenodd">
        <mask id="20_svg__b" fill="#fff">
          <use xlinkHref="#20_svg__a" />
        </mask>
        <motion.path
          fill="#FFE7D7"
          mask="url(#20_svg__b)"
          d={`M1 ${lastTopStart}h30v${lastTopHeight}H1z`}
          animate={{ d: `M1 ${topStart}h30v${topHeight}H1z` }}
          transition={{ duration: DURATION }}
        />
        <motion.path
          fill="#D26F60"
          mask="url(#20_svg__b)"
          d={`M1 ${lastMiddleStart}h30v${lastMiddleHeight}H1z`}
          animate={{ d: `M1 ${middleStart}h30v${middleHeight}H1z` }}
          transition={{ duration: DURATION }}
        />
        <motion.path
          fill="#F99545"
          mask="url(#20_svg__b)"
          d={`M1 ${lastBottomStart}h30v${lastBottomHeight}H1z`}
          animate={{ d: `M1 ${bottomStart}h30v${bottomHeight}H1z` }}
          transition={{ duration: DURATION }}
        />
        <rect
          width={30}
          height={99}
          x={1}
          y={1}
          stroke="#231F20"
          strokeWidth={2}
          rx={15}
        />
        <text
          textAnchor="middle"
          fill="#000"
          fontFamily="Montserrat-SemiBold, Montserrat"
          fontSize={10}
          fontWeight={500}
          letterSpacing={-0.229}
          x={16}
          y={91}
        >
          <FramerText
            value={bottomHeight}
            lastValue={lastBottomHeight}
            suffix={'%'}
            order={scenario && scenario.order ? scenario.order : 1}
          />
        </text>
        <text
          textAnchor="middle"
          fill="#000"
          fontFamily="Montserrat-SemiBold, Montserrat"
          fontSize={10}
          fontWeight={500}
          letterSpacing={-0.229}
          x={16}
          y={55}
        >
          <FramerText
            value={middleHeight}
            lastValue={lastMiddleHeight}
            suffix={'%'}
            order={scenario && scenario.order ? scenario.order : 1}
          />
        </text>
        <text
          textAnchor="middle"
          fill="#000"
          fontFamily="Montserrat-SemiBold, Montserrat"
          fontSize={10}
          fontWeight={500}
          letterSpacing={-0.229}
          x={16}
          y={19}
        >
          <FramerText
            value={topHeight}
            lastValue={lastTopHeight}
            suffix={'%'}
            order={scenario && scenario.order ? scenario.order : 1}
          />
        </text>
      </g>
    </svg>
  )
}

export default Svg20
