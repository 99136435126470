export const MODAL_TYPES = {
  REFERENCES: 'REFERENCES',
  SCENARIO: 'SCENARIO',
  CAPTION_READ_MORE: 'CAPTION_READ_MORE',
}

export const WIDTH = 320
export const SVGDIMENSIONS = {
  width: 1135,
  height: 715,
}
export const CITYWIDTH = 850
export const CARWIDTH = 39

// for the circles and pills to animate to
export const DURATION = 0.4
// for the scenario text...can specify if we want the text to appear before the end of the rect/circle animation
export const S_DURATION = 2
export const DELAY = 0.1

// Readable Site Structure, Sections and Chapters
export const structure = {
  Introduction: ['Intro', 'Autonomous Mobility', 'Shared Mobility'],
  'Understanding Congestion': [
    'Congestion',
    'Saturation level',
    'Demand',
    'City and time of day',
    'Baseline occupancy',
    'Demographics',
    'Shared mobility',
    'New Occupancy',
    'Cost of car travel',
    'Demand response',
    'Road user charging',
    'New demand',
    'Road capacity',
    'Self-driving vehicles',
    'Headway impact',
    'Induced demand',
    'New Capacity',
  ],
  Scenarios: [
    // 'Doomsday_1',
    // 'Doomsday_2',
    // 'Doomsday_3',
    // 'Doomsday_4',
    // 'Doomsday_5',
    // 'Doomsday_6',
    // 'Free Flow Mobility',
    'Doomsday_1',
    'Doomsday_2',
    // 'Doomsday_3',
    // 'Doomsday_4',
    // 'Doomsday_5',
    // 'Doomsday_6',
    // 'Doomsday_7',
    // 'Doomsday_8',
    // 'Doomsday_9',
    // 'Doomsday_10',
    // 'Doomsday_11',
    // 'Doomsday_12',
    // 'Doomsday_13',
    'Free flow mobility_1',
    'Free flow mobility_2',
    'Free flow mobility_3',
    'Free flow mobility_4',
    // 'Free flow mobility_5',
    // 'Free flow mobility_6',
    // 'Free flow mobility_7',
    // 'Free flow mobility_8',
    // 'Free flow mobility_9',
    // 'Free flow mobility_10',
    // 'Free flow mobility_11',
    // 'Free flow mobility_12',
    // 'Free flow mobility_13',
    // 'Free flow mobility_14',
    // 'Free flow mobility_15',
    // 'Free flow mobility_16',
  ],
  'Taking Action': ['Taking Action'],
}

// combine arrays of above for a list of Chapters
export const contents = Object.keys(structure).reduce((acc, section) => {
  return [...acc, ...structure[section]]
}, [])

// create a quick lookup of Chapters to Sections
export const sections = Object.keys(structure).reduce((acc, section) => {
  const obj = Object.assign(
    ...structure[section].map(step => {
      return {
        [step]: section,
      }
    })
  )
  return {
    ...acc,
    ...obj,
  }
}, {})

// N.B. these numbers have been worked out manually
// Don't change without asking!
// size, car, and carDelay are important to consider together
// as we need the cars to 'tile' correctly across animation
// loops, otherwise we get a pile-up :/
export const SCENARIO_SPEEDS = {
  Doomsday: {
    car: 40,
    carDelay: 2,
    city: 2000,
    road: 1000,
    size: 24,
  },
  Default: {
    car: 40,
    carDelay: 3,
    city: 400,
    road: 50,
    size: 13,
  },
  'Free flow mobility': {
    car: 20,
    carDelay: 4,
    city: 100,
    road: 10,
    size: 5,
  },
}
