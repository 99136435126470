import React from 'react'
import Dialog from '@material-ui/core/Dialog'
import {
  FallbackModal,
  ReferencesModal,
  ScenarioModal,
  CaptionReadMoreModal,
} from './Modals'
import { MODAL_TYPES } from '../constants/config'

const MODAL_REF = {
  [MODAL_TYPES.REFERENCES]: ReferencesModal,
  [MODAL_TYPES.SCENARIO]: ScenarioModal,
  [MODAL_TYPES.CAPTION_READ_MORE]: CaptionReadMoreModal,
}

const ModalManager = ({ modalState, setModalState }) => {
  const { modalType, options = {} } = modalState
  const { modalProps = {}, ...miscOptions } = options

  if (!modalType) {
    return null
  }

  const ModalContent = MODAL_REF[modalType] || FallbackModal
  const hideModal = () => setModalState({ modalType: null })

  return (
    <Dialog open={true} onClose={hideModal} {...miscOptions}>
      <ModalContent hideModal={hideModal} {...modalProps} />
    </Dialog>
  )
}

export default ModalManager
