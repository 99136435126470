import React from 'react'

const Svg62 = props => (
  <svg width={32} height={9} {...props}>
    <path
      d="M.05 8V1h1v6.13h3.79V8H.05zm10.291-2.62c0 .08-.01.2-.02.29h-4.3c.12.93.87 1.55 1.92 1.55.62 0 1.14-.21 1.53-.64l.53.62c-.48.56-1.21.86-2.09.86-1.71 0-2.85-1.13-2.85-2.71 0-1.57 1.13-2.7 2.66-2.7 1.53 0 2.62 1.1 2.62 2.73zm-2.62-1.92c-.92 0-1.6.62-1.7 1.52h3.4c-.1-.89-.77-1.52-1.7-1.52zm7.372-.76h.94L13.713 8h-.98l-2.32-5.3h1l1.82 4.25 1.86-4.25zm6.251 2.68c0 .08-.01.2-.02.29h-4.3c.12.93.87 1.55 1.92 1.55.62 0 1.14-.21 1.53-.64l.53.62c-.48.56-1.21.86-2.09.86-1.71 0-2.85-1.13-2.85-2.71 0-1.57 1.13-2.7 2.66-2.7 1.53 0 2.62 1.1 2.62 2.73zm-2.62-1.92c-.92 0-1.6.62-1.7 1.52h3.4c-.1-.89-.77-1.52-1.7-1.52zM22.446 8V.58h.96V8h-.96zm7.273-4.06c1.4.13 2.11.92 2.11 2 0 1.18-.87 2.14-2.65 2.14-1 0-1.98-.32-2.57-.85l.46-.79c.47.44 1.25.75 2.11.75 1.05 0 1.65-.48 1.65-1.24 0-.74-.53-1.22-1.73-1.22h-.56v-.7l1.76-2.16h-3.37V1h4.62v.68l-1.83 2.26z"
      fill="#122373"
      fillRule="nonzero"
    />
  </svg>
)

export default Svg62
