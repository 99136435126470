import React from 'react'
import FramerText from '../hocs/FramerText'

let cached = 0

const Svg4 = ({ scenario, ...rest }) => {
  const value = scenario ? scenario.value : '1,103'
  const lastValue = cached

  cached = value

  return (
    <svg width={50} height={66} {...rest}>
      <g transform="translate(-1 -2)" fill="none" fillRule="evenodd">
        <rect width={50} height={51} x={1} y={17} fill="#C15727" rx={4.5} />
        <path stroke="#FFF" d="M1 54.5h50" />
        <path
          fill="#FFF"
          fillRule="nonzero"
          d="M16.694 59.23h.846L15.452 64h-.882l-2.088-4.77h.9l1.638 3.825 1.674-3.825zm5.626 2.412c0 .072-.009.18-.018.261h-3.87c.108.837.783 1.395 1.728 1.395.558 0 1.026-.189 1.377-.576l.477.558c-.432.504-1.089.774-1.88.774-1.54 0-2.566-1.017-2.566-2.439 0-1.413 1.017-2.43 2.394-2.43 1.377 0 2.358.99 2.358 2.457zm-2.358-1.728c-.828 0-1.44.558-1.53 1.368h3.06c-.09-.801-.693-1.368-1.53-1.368zm5.897-.729c1.16 0 1.98.666 1.98 2.07V64h-.864v-2.646c0-.936-.468-1.395-1.287-1.395-.918 0-1.512.549-1.512 1.584V64h-.864v-6.678h.864v2.583c.36-.459.954-.72 1.683-.72zm2.305 5.715l2.97-8.478h.783l-2.97 8.478h-.783zm6.58-5.715c1.161 0 1.98.666 1.98 2.07V64h-.864v-2.646c0-.936-.468-1.395-1.287-1.395-.918 0-1.512.549-1.512 1.584V64h-.864v-6.678h.864v2.583c.36-.459.954-.72 1.683-.72z"
        />
        <text
          fill="#FFF"
          fontFamily="Montserrat-SemiBold, Montserrat"
          fontSize={16}
          fontWeight={500}
          letterSpacing={-0.366}
          textAnchor="middle"
          alignmentBaseline="middle"
        >
          <FramerText
            value={value}
            lastValue={lastValue}
            order={scenario && scenario.order ? scenario.order : 1}
            // position relative to rect's dimensions
            x={1 + 25}
            y={17 + 25}
          />
        </text>
        <path
          fill="#C15727"
          fillRule="nonzero"
          d="M2.83 10V3h3.18C8.3 3 9.87 4.38 9.87 6.5S8.3 10 6.01 10H2.83zm1.62-1.33h1.48c1.39 0 2.3-.83 2.3-2.17 0-1.34-.91-2.17-2.3-2.17H4.45v4.34zm11.571-1.34c0 .12-.02.29-.03.42h-4.07c.15.67.72 1.08 1.54 1.08.57 0 .98-.17 1.35-.52l.83.9c-.5.57-1.25.87-2.22.87-1.86 0-3.07-1.17-3.07-2.77 0-1.61 1.23-2.77 2.87-2.77 1.58 0 2.8 1.06 2.8 2.79zm-2.79-1.61c-.71 0-1.22.43-1.33 1.12h2.65c-.11-.68-.62-1.12-1.32-1.12zm10.492-1.18c1.28 0 2.2.74 2.2 2.38V10h-1.56V7.16c0-.87-.39-1.27-1.02-1.27-.7 0-1.19.45-1.19 1.41V10h-1.56V7.16c0-.87-.37-1.27-1.02-1.27-.69 0-1.18.45-1.18 1.41V10h-1.56V4.62h1.49v.62c.4-.46 1-.7 1.69-.7.75 0 1.39.29 1.77.88.43-.55 1.13-.88 1.94-.88zm5.541 0c1.64 0 2.59.76 2.59 2.39V10h-1.46v-.67c-.29.49-.85.75-1.64.75-1.26 0-2.01-.7-2.01-1.63 0-.95.67-1.61 2.31-1.61h1.24c0-.67-.4-1.06-1.24-1.06-.57 0-1.16.19-1.55.5l-.56-1.09c.59-.42 1.46-.65 2.32-.65zm-.12 4.49c.52 0 .97-.24 1.15-.73v-.55h-1.07c-.73 0-.96.27-.96.63 0 .39.33.65.88.65zm7.162-4.49c1.27 0 2.23.74 2.23 2.38V10h-1.56V7.16c0-.87-.4-1.27-1.09-1.27-.75 0-1.29.46-1.29 1.45V10h-1.56V4.62h1.49v.63c.42-.46 1.05-.71 1.78-.71zm7.391-1.96h1.56V10h-1.49v-.62c-.39.47-.96.7-1.68.7-1.52 0-2.69-1.08-2.69-2.77s1.17-2.77 2.69-2.77c.66 0 1.22.21 1.61.65V2.58zm-1.34 6.22c.77 0 1.37-.56 1.37-1.49 0-.93-.6-1.49-1.37-1.49-.78 0-1.38.56-1.38 1.49 0 .93.6 1.49 1.38 1.49z"
        />
      </g>
    </svg>
  )
}

export default Svg4
