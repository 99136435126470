import React from 'react'
import styled from '@emotion/styled'
import { structure } from '../../constants/config'
import { motion } from 'framer-motion'
import { ArrowLeft, ArrowRight } from '../Icons'

const { Introduction } = structure

const Container = styled.div`
  border-bottom: 3px solid ${props => props.theme.lightgrey2};
  margin: 4rem 0 0.5rem;
  padding: 0.5rem 0;
  display: flex;
  align-items: center;
`
const NavArrow = styled.div`
  font-size: 0.875rem;
  font-weight: 500;
  display: flex;
  align-items: center;
  cursor: pointer;

  > * + * {
    margin-left: 0.5rem;
  }
  svg {
    display: block;
  }
`
// const IconContainer = styled.div`
//   margin-top: 0.25rem;
//   display: flex;
//   align-items: center;
//   cursor: pointer;
// `

const LeftArrow = ({ label, onClick }) => (
  <NavArrow onClick={onClick}>
    <motion.div whileHover={{ scale: 1.2 }} whileTap={{ scale: 0.8 }}>
      <ArrowLeft height={20} fill={'#333'} />
    </motion.div>
    <div>{label}</div>
  </NavArrow>
)
const RightArrow = ({ label, onClick }) => (
  <NavArrow style={{ marginLeft: 'auto' }} onClick={onClick}>
    <div>{label}</div>
    <motion.div whileHover={{ scale: 1.2 }} whileTap={{ scale: 0.8 }}>
      <ArrowRight height={20} fill={'#333'} />
    </motion.div>
  </NavArrow>
)

const getPrev = chapter => {
  const pos = Introduction.indexOf(chapter)
  return pos > 0 ? Introduction[pos - 1] : null
}
const getNext = chapter => {
  const pos = Introduction.indexOf(chapter)
  return pos < Introduction.length - 1
    ? Introduction[pos + 1]
    : 'Understanding Congestion'
}
const TopBar = ({ chapter, nextStep, prevStep }) => {
  const prev = getPrev(chapter)
  const next = getNext(chapter)
  return (
    <Container>
      {prev && <LeftArrow label={prev} onClick={prevStep} />}
      {next && <RightArrow label={next} onClick={nextStep} />}
    </Container>
  )
}

export default TopBar
