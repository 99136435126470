import React from 'react'

const Svg56 = props => (
  <svg width={291} height={136} {...props}>
    <g stroke="#ED5835" fill="none" fillRule="evenodd">
      <path d="M0 1h229a6 6 0 016 6v66a6 6 0 006 6h43a6 6 0 016 6v33h0" />
      <path d="M205 135h24a6 6 0 006-6V85a6 6 0 016-6h8" />
    </g>
  </svg>
)

export default Svg56
