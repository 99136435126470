import React, { Component } from 'react'
import styled from '@emotion/styled'

const G = styled.g`
  path {
    opacity: 0;
    stroke-dasharray: 1500;
    stroke-dashoffset: 1500;
    animation: dash 5s linear forwards;
    animation-delay: ${props => props.delay}s;
  }

  @keyframes dash {
    from {
      opacity: 1;
    }
    to {
      opacity: 1;
      stroke-dashoffset: 0;
    }
  }
`

export default WrappedComponent => {
  class LineTransition extends Component {
    render() {
      const { order, ...rest } = this.props
      return (
        <G delay={order - 1}>
          <WrappedComponent {...rest} />
        </G>
      )
    }
  }
  return LineTransition
}
