import React from 'react'
import FramerText from '../hocs/FramerText'

let cached = 0

const Svg46 = ({ scenario, ...rest }) => {
  let value = scenario ? scenario.value : 0.76
  value = value.toFixed(2)
  const lastValue = cached

  cached = value
  return (
    <svg width={45} height={26} {...rest}>
      <g fill="none" fillRule="evenodd">
        <rect
          width={44}
          height={25}
          x={0.5}
          y={0.5}
          rx={4.5}
          fill="#FCFCF9"
          stroke="#B77941"
        />
        <text
          fill="#231F20"
          fontFamily="Montserrat-SemiBold, Montserrat"
          fontSize={9}
          fontWeight={500}
          letterSpacing={-0.206}
        >
          <FramerText
            value={value}
            lastValue={lastValue}
            prefix={'\xA3'}
            order={scenario && scenario.order ? scenario.order : 1}
            x={10.243}
            y={16}
          />
        </text>
      </g>
    </svg>
  )
}
export default Svg46
