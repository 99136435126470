import React from 'react'

const Svg57 = props => (
  <svg width={1} height={32} {...props}>
    <path
      d="M.5.5v31"
      stroke="#122373"
      fill="none"
      fillRule="evenodd"
      strokeLinecap="square"
    />
  </svg>
)

export default Svg57
