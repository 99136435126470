import React from 'react'

const Svg35 = props => (
  <svg width={168} height={77} {...props}>
    <path
      d="M0 1h133.952a2.836 2.836 0 012.837 2.836v10.455h0a3.71 3.71 0 003.71 3.709H168h-27.502a3.71 3.71 0 00-3.709 3.71h0v51.454A2.836 2.836 0 01133.953 76H110"
      stroke="#ED5835"
      fill="none"
      fillRule="evenodd"
    />
  </svg>
)

export default Svg35
