import React from 'react'

import NavBtns from '../NavBtns'
import SideSection from '../../hocs/SideSection'
import Content from '../UnderstandingCongestion/Content'
import { Info, P } from '../../common/Styles'

const UnderstandingCongestionPanel = ({
  selectedChapter,
  size,
  step,
  nextStep,
  prevStep,
}) => {
  return (
    <>
      <NavBtns
        size={size}
        step={step}
        nextStep={nextStep}
        prevStep={prevStep}
        title={selectedChapter}
      />
      <Info>
        <P>{Content[selectedChapter]}</P>
      </Info>
    </>
  )
}

export default SideSection(UnderstandingCongestionPanel)
