import React, { Component } from 'react'
import styled from '@emotion/styled'
import { ModalContent } from '../ModalUtils'
import { UL, LI } from '../../common/Styles'
import TEXT_CONTENT from '../../constants/textContent'
import { Close } from '../Icons'

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 2rem;
`

const Title = styled.h3`
  margin: 0;
`

class CaptionReadMoreModal extends Component {
  render() {
    const { scenario, scenarioStep, hideModal } = this.props

    const { caption, modalContent } = TEXT_CONTENT.scenarios[scenario][
      scenarioStep
    ]

    return (
      <ModalContent>
        <Header>
          <Title>{caption.text}</Title>
          <Close
            height="24px"
            style={{ cursor: 'pointer' }}
            onClick={hideModal}
          />
        </Header>
        <UL>
          {modalContent.map((text, i) => (
            <LI key={i}>{text}</LI>
          ))}
        </UL>
      </ModalContent>
    )
  }
}

export default CaptionReadMoreModal
