import React from 'react'

const Svg48 = props => (
  <svg width={143} height={74} {...props}>
    <path
      d="M0 1h2.21a3 3 0 013 3v30a3 3 0 003 3H143 0h11.267-3a3 3 0 00-3 3v30c0 1.658-1.344 3-3 3L0 72.992"
      stroke="#B77941"
      fill="none"
      fillRule="evenodd"
    />
  </svg>
)

export default Svg48
