import React from 'react'

const Svg52 = props => (
  <svg width={286} height={229} {...props}>
    <path
      d="M0 1h268.5c9.15 0 16.569 7.418 16.569 16.569v3.286L285 229"
      stroke="#ED5835"
      fill="none"
      fillRule="evenodd"
      strokeLinecap="square"
    />
  </svg>
)

export default Svg52
