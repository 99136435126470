import React from 'react'

const SvgCity = props => (
  <svg width={852} height={187} {...props}>
    <defs>
      <linearGradient
        id="city_svg__i"
        x1="50%"
        x2="50%"
        y1="-36.893%"
        y2="100%"
      >
        <stop offset="0%" stopColor="#E6EFF2" />
        <stop offset="100%" stopColor="#F8F4EE" />
      </linearGradient>
      <linearGradient
        id="city_svg__a"
        x1="50%"
        x2="50%"
        y1="-36.893%"
        y2="100%"
      >
        <stop offset="0%" stopColor="#E6EFF2" />
        <stop offset="100%" stopColor="#F8F4EE" />
      </linearGradient>
      <linearGradient
        id="city_svg__c"
        x1="50%"
        x2="50%"
        y1="-36.893%"
        y2="100%"
      >
        <stop offset="0%" stopColor="#E6EFF2" />
        <stop offset="100%" stopColor="#F8F4EE" />
      </linearGradient>
      <linearGradient
        id="city_svg__d"
        x1="50%"
        x2="50%"
        y1="-36.893%"
        y2="100%"
      >
        <stop offset="0%" stopColor="#E6EFF2" />
        <stop offset="100%" stopColor="#F8F4EE" />
      </linearGradient>
      <linearGradient
        id="city_svg__e"
        x1="50%"
        x2="50%"
        y1="-36.893%"
        y2="100%"
      >
        <stop offset="0%" stopColor="#E6EFF2" />
        <stop offset="100%" stopColor="#F8F4EE" />
      </linearGradient>
      <linearGradient
        id="city_svg__f"
        x1="50%"
        x2="50%"
        y1="-36.893%"
        y2="100%"
      >
        <stop offset="0%" stopColor="#E6EFF2" />
        <stop offset="100%" stopColor="#F8F4EE" />
      </linearGradient>
      <linearGradient
        id="city_svg__g"
        x1="50%"
        x2="50%"
        y1="-36.893%"
        y2="100%"
      >
        <stop offset="0%" stopColor="#E6EFF2" />
        <stop offset="100%" stopColor="#F8F4EE" />
      </linearGradient>
      <linearGradient
        id="city_svg__h"
        x1="50%"
        x2="50%"
        y1="-36.893%"
        y2="100%"
      >
        <stop offset="0%" stopColor="#E6EFF2" />
        <stop offset="100%" stopColor="#F8F4EE" />
      </linearGradient>
      <linearGradient
        id="city_svg__b"
        x1="50%"
        x2="50%"
        y1="-36.893%"
        y2="100%"
      >
        <stop offset="0%" stopColor="#E6EFF2" />
        <stop offset="100%" stopColor="#F8F4EE" />
      </linearGradient>
      <linearGradient
        id="city_svg__j"
        x1="50%"
        x2="50%"
        y1="-36.893%"
        y2="100%"
      >
        <stop offset="0%" stopColor="#E6EFF2" />
        <stop offset="100%" stopColor="#F8F4EE" />
      </linearGradient>
      <linearGradient
        id="city_svg__k"
        x1="50%"
        x2="50%"
        y1="-36.893%"
        y2="100%"
      >
        <stop offset="0%" stopColor="#E6EFF2" />
        <stop offset="100%" stopColor="#F8F4EE" />
      </linearGradient>
      <linearGradient
        id="city_svg__l"
        x1="50%"
        x2="50%"
        y1="-36.893%"
        y2="100%"
      >
        <stop offset="0%" stopColor="#E6EFF2" />
        <stop offset="100%" stopColor="#F8F4EE" />
      </linearGradient>
      <linearGradient
        id="city_svg__m"
        x1="50%"
        x2="50%"
        y1="-36.893%"
        y2="100%"
      >
        <stop offset="0%" stopColor="#E6EFF2" />
        <stop offset="100%" stopColor="#F8F4EE" />
      </linearGradient>
      <linearGradient
        id="city_svg__n"
        x1="50%"
        x2="50%"
        y1="-36.893%"
        y2="100%"
      >
        <stop offset="0%" stopColor="#E6EFF2" />
        <stop offset="100%" stopColor="#F8F4EE" />
      </linearGradient>
      <linearGradient
        id="city_svg__o"
        x1="50%"
        x2="50%"
        y1="-36.893%"
        y2="100%"
      >
        <stop offset="0%" stopColor="#E6EFF2" />
        <stop offset="100%" stopColor="#F8F4EE" />
      </linearGradient>
      <linearGradient
        id="city_svg__p"
        x1="50%"
        x2="50%"
        y1="-36.893%"
        y2="100%"
      >
        <stop offset="0%" stopColor="#E6EFF2" />
        <stop offset="100%" stopColor="#F8F4EE" />
      </linearGradient>
      <linearGradient
        id="city_svg__q"
        x1="50%"
        x2="50%"
        y1="-36.893%"
        y2="100%"
      >
        <stop offset="0%" stopColor="#E6EFF2" />
        <stop offset="100%" stopColor="#F8F4EE" />
      </linearGradient>
    </defs>
    <g fill="none" fillRule="evenodd">
      <path
        fill="url(#city_svg__a)"
        d="M483.182 186.154h30.032V60.201l-30.032-13.484zM217.182 186.154h30.032V60.201l-30.032-13.484z"
      />
      <path
        fill="url(#city_svg__b)"
        d="M459.585 186.154h29.604v-77.225h-29.604zM193.585 186.154h29.604v-77.225h-29.604z"
      />
      <path
        fill="url(#city_svg__c)"
        d="M431.698 186.154h27.887V70.743h-27.887zM165.698 186.154h27.887V70.743h-27.887z"
      />
      <path
        fill="url(#city_svg__d)"
        d="M439.899 155.149h11.486V39.739h-11.486zM173.899 155.149h11.486V39.739h-11.486z"
      />
      <path
        fill="url(#city_svg__e)"
        d="M444.536 115.41h2.21V0h-2.21zM178.536 115.41h2.21V0h-2.21z"
      />
      <path
        fill="url(#city_svg__f)"
        d="M836.761 186.154h14.588v-84.091h-14.588zM420.114 186.154h14.588v-84.091h-14.588zM154.114 186.154h14.588v-84.091h-14.588z"
      />
      <path
        fill="url(#city_svg__g)"
        d="M823.891 186.154h16.303V89.192h-16.303zM407.244 186.154h16.303V89.192h-16.303zM141.244 186.154h16.303V89.192h-16.303z"
      />
      <path
        fill="url(#city_svg__h)"
        d="M793 39.424v146.73h33.464V21.561zM376.353 39.424v146.73h33.464V21.561zM110.353 39.424v146.73h33.464V21.561z"
      />
      <path
        fill="url(#city_svg__i)"
        d="M733.745 186.154h35.61V73.747h-35.61zM343.745 186.154h35.61V73.747h-35.61zM77.745 186.154h35.61V73.747h-35.61z"
      />
      <path
        fill="url(#city_svg__j)"
        d="M720.875 186.154h16.303v-63.497h-16.303zM330.875 186.154h16.303v-63.497h-16.303zM62.875 186.154h16.303v-63.497H62.875z"
      />
      <path
        fill="url(#city_svg__k)"
        d="M702.855 186.154h20.594V90.48h-20.594zM312.855 186.154h20.594V90.48h-20.594zM43.855 186.154h20.594V90.48H43.855z"
      />
      <path
        fill="url(#city_svg__l)"
        d="M659.523 74.605v111.549h43.762V74.605l-21.84-14.004zM269.523 74.605v111.549h43.762V74.605l-21.84-14.004zM.523 74.605v111.549h43.762V74.605l-21.84-14.004z"
      />
      <path
        fill="url(#city_svg__m)"
        d="M618.336 186.154h45.477v-78.512h-45.477zM228.336 186.154h45.477v-78.512h-45.477z"
      />
      <path
        fill="url(#city_svg__n)"
        d="M593.452 186.154h29.175V56.157h-29.175zM203.452 186.154h29.175V56.157h-29.175z"
      />
      <path
        fill="url(#city_svg__o)"
        d="M572 186.154h24.455V96.057H572zM769 186.154h24.455V96.057H769zM182 186.154h24.455V96.057H182z"
      />
      <path
        fill="url(#city_svg__p)"
        d="M508.306 186.154h38.864V97.653h-38.864zM242.306 186.154h38.864V97.653h-38.864z"
      />
      <path
        fill="url(#city_svg__q)"
        d="M544.219 186.154h28.532v-76.202h-28.532zM278.219 186.154h28.532v-76.202h-28.532z"
      />
    </g>
  </svg>
)

export default SvgCity
