import React from 'react'

const Svg54 = props => (
  <svg width={58} height={28} {...props}>
    <path
      d="M58 1H39.355c-1.48 0-2.679 1.2-2.679 2.679h0v21.273A2.048 2.048 0 0134.628 27h-8.085 0H0"
      stroke="#B77941"
      fill="none"
      fillRule="evenodd"
    />
  </svg>
)

export default Svg54
