import styled from '@emotion/styled'

export const H3 = styled.h3`
  color: ${props => props.theme[props.color]};
`
export const H5 = styled.h5`
  font-size: 0.75rem;
  font-weight: bold;
  margin: 0;
  color: ${props => props.theme[props.color]};
`
export const P = styled.p`
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1.5;
`

export const UL = styled.ul`
  padding-inline-start: 20px;
  li + li {
    margin-top: 1rem;
  }
`

export const LI = styled.li`
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1.5;
`

export const Info = styled.div`
  margin: 0 0 1rem 0;
  max-height: 360px;
  overflow-y: auto;
`
