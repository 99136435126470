import React from 'react'
import styled from '@emotion/styled'

import { P } from '../../common/Styles'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 90%;
`
const Headline = styled.div`
  margin-top: 50px;
  margin-bottom: 25px;
`
const Title = styled.div`
  font-size: 2.5rem;
  font-weight: bold;
  line-height: 1.25;
`
const Text = styled.span`
  color: ${props => props.theme[props.color]};
`

const Intro = () => {
  return (
    <Container>
      <Headline>
        <Title>
          <Text color={'black'}>Exploring the impacts of </Text>
        </Title>
        <Title>
          <Text color={'green'}>Self driving vehicles </Text>
          <Text>and</Text>
        </Title>
        <Title>
          <Text color={'orange'}>Shared Mobility </Text>
          <Text color={'black'}>on</Text>
        </Title>
        <Title>
          <Text color={'congestionRed'}>Congestion</Text>
        </Title>
      </Headline>
      <P>
        Over time, shared and autonomous mobility will profoundly affect cities.
        It is not clear when these technologies will be adopted. Deployment will
        be stepped, gradual, uneven and contextual.
      </P>

      <P>
        Transport engineers hoped that autonomy would be the silver bullet
        solution to congestion – with interconnected platooning vehicles flowing
        through junctions very efficiently.
      </P>

      <P>
        Whilst this was an exciting claim, it ignored a big part of the picture.
        We considered 5 impact variables that will have an impact on traffic
        patterns under different adoption level of autonomy and shared mobility.
        In the next section, we will introduce these in the context of
        understanding congestion But first, an overview of autonomy and shared
        mobility.
      </P>
    </Container>
  )
}

export default Intro
