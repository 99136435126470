import React from 'react'
import styled from '@emotion/styled'

import Action from './Action'

import { WIDTH } from '../../constants/config'

const Container = styled.div`
  margin-left: ${props => props.width}px;
  margin-right: ${props => props.width}px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const HorizontalRule = styled.div`
  border-bottom: 3px solid ${props => props.theme.lightgrey2};
  margin: 4rem 0 0.5rem;
  padding: 1.1rem 0;
  display: flex;
  align-items: center;
`
const Inner = styled.div`
  width: 655px;
`

const TakingAction = ({ setModalState }) => {
  return (
    <Container width={WIDTH}>
      <Inner>
        <HorizontalRule />
        <Action setModalState={setModalState} />
      </Inner>
    </Container>
  )
}

export default TakingAction
