import React, { memo } from 'react'
import styled from '@emotion/styled'
import CarSvg from '../../svgs/Car'
import { CITYWIDTH, CARWIDTH } from '../../constants/config'

const G = styled.g`
  opacity: 0;
  animation: car-${props => props.name} ${props => props.time}s linear infinite;
  animation-delay: ${props => props.delay}s;
  @keyframes car-${props => props.name} {
    from {
      opacity: 1;
      transform: translate3d(${props => props.from}px, 0, 0);
    }
    to {
      opacity: 1;
      transform: translate3d(${props => props.to}px, 0, 0);
    }
  }
`

const getRandomInt = (min, max) => {
  return Math.random() * (max - min) + min
}

const Car = memo(props => {
  const { speed, size, x, y, delay } = props
  const data = Array(size)
    .fill(null)
    .map((u, i) => i)

  let topLaneCars = []
  let bottomLaneCars = []

  let topLaneCounter = 0
  let bottomLaneCounter = 0

  data.forEach(f => {
    // Delay is a fixed number passed in via props now
    // Use below to introduce randomness later on
    // const delay = getRandomInt(f * 5, (f + 1) * 5)

    const graphic = (
      <G
        key={`car-${f}`}
        name={f}
        to={CITYWIDTH}
        from={-CARWIDTH}
        time={speed}
        delay={delay * f}
      >
        <CarSvg x={x} y={0} />
      </G>
    )

    let lane
    const randomNum = Math.random()

    // Max three cars in a row
    if (randomNum >= 0.5 || topLaneCounter === 3) {
      lane = bottomLaneCars
      bottomLaneCounter += 1
      topLaneCounter = 0
    } else if (randomNum < 0.5 || bottomLaneCounter === 3) {
      lane = topLaneCars
      topLaneCounter += 1
      bottomLaneCounter = 0
    }

    lane.push(graphic)
  })

  return (
    <g>
      {/* Top lane */}
      <g transform={`translate(0, ${y - 10})`}>{topLaneCars}</g>

      {/* Bottom lane */}
      <g transform={`translate(0, ${y})`}>{bottomLaneCars}</g>
    </g>
  )
})

export default Car
