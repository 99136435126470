import React, { Component } from 'react'
import styled from '@emotion/styled'

const Container = styled.div`
  padding: 0.5rem 0;
`
const Title = styled.div`
  font-size: 14px;
  font-weight: bold;
  color: ${props => props.theme[props.color]};
  cursor: pointer;
`
const ProgressContainer = styled.div`
  display: flex;
  margin: 0.5rem 0;
`
const Line = styled.div`
  width: ${props => props.width}%;
  border-bottom: 3px solid ${props => props.theme[props.color]};
  transition: width 0.6s linear;
`

const Progress = ({ width }) => {
  return (
    <ProgressContainer>
      <Line width={width} color={'black'} />
      <Line width={100 - width} color={'grey'} />
    </ProgressContainer>
  )
}
const Underline = () => (
  <ProgressContainer>
    <Line width={100} color={'grey'} />
  </ProgressContainer>
)

export default WrappedComponent => {
  class SideSection extends Component {
    toStep = () => this.props.toStep(this.props.quickStep)
    render() {
      const { selected, title, chapters, selectedChapter } = this.props
      const color = selected ? 'black' : 'lightgrey'
      const percentage = !selected
        ? 0
        : ((chapters.indexOf(selectedChapter) + 1) / chapters.length) * 100
      return (
        <Container>
          <Title color={color} onClick={this.toStep}>
            {title}
          </Title>
          {selected ? (
            <Progress width={percentage} selected={selected} />
          ) : (
            <Underline />
          )}
          {selected && <WrappedComponent {...this.props} />}
        </Container>
      )
    }
  }
  return SideSection
}
