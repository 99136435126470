import React, { useState, useEffect, memo, useMemo } from 'react'
import styled from '@emotion/styled'

import SidePanel from '../SidePanel'
import Introduction from '../Introduction'
import VizCanvas from '../VizCanvas'

import useKeys from '../../hooks/useKeys'
import useFetch from '../../hooks/useFetch'
import {
  structure,
  contents,
  sections,
  WIDTH,
  MODAL_TYPES,
} from '../../constants/config'
import TakingAction from '../TakingAction'

const Container = styled.div`
  height: 100vh;
  width: 100%;
`
const size = contents.length
const visOffset = structure['Introduction'].length

const Page = ({ setModalState, modalIsOpen }) => {
  const [data, loading] = useFetch('./data.json')
  const [step, setStep] = useState(0)

  useEffect(() => {
    // Specific "steps", start of each scenario
    const isFirstDoomsdayStep = step === contents.indexOf('Doomsday_1')
    const isFirstFreeflowStep =
      step === contents.indexOf('Free flow mobility_1')

    if (isFirstDoomsdayStep) {
      setModalState({
        modalType: MODAL_TYPES.SCENARIO,
        options: {
          modalProps: {
            scenario: 'Doomsday',
          },
        },
      })
    }

    if (isFirstFreeflowStep) {
      setModalState({
        modalType: MODAL_TYPES.SCENARIO,
        options: {
          modalProps: {
            scenario: 'Free flow mobility',
          },
        },
      })
    }
  }, [step])

  // Split data up for vis and for sidebar component(s)
  const { sidePanelData, vizCanvasData } = useMemo(() => {
    const sidePanelDataIds = [81, 82, 84, 85]

    return data.reduce(
      (memo, el) => {
        if (sidePanelDataIds.indexOf(el.id) === -1) {
          memo.vizCanvasData.push(el)
        } else {
          memo.sidePanelData.push(el)
        }

        return memo
      },
      {
        sidePanelData: [],
        vizCanvasData: [],
      }
    )
  }, [data])

  const nextStep = () => setStep(f => (f < size ? f + 1 : f))
  const prevStep = () => setStep(f => (f > 0 ? f - 1 : f))
  const toStep = pos => setStep(f => pos)

  useKeys('right', nextStep, modalIsOpen, [size, modalIsOpen])
  useKeys('left', prevStep, modalIsOpen, [size, modalIsOpen])

  if (loading) {
    return <div>Loading...</div>
  } else {
    const chapter = contents[step]
    const selectedSection = sections[chapter]
    const offset = step - visOffset

    const selectedScenario =
      selectedSection !== 'Scenarios' ? 'Default' : chapter

    return (
      <Container width={WIDTH}>
        <SidePanel
          size={size}
          step={step}
          nextStep={nextStep}
          prevStep={prevStep}
          chapter={chapter}
          selectedSection={selectedSection}
          toStep={toStep}
          setModalState={setModalState}
          visData={sidePanelData}
        />
        {selectedSection === 'Introduction' && (
          <Introduction
            chapter={chapter}
            nextStep={nextStep}
            prevStep={prevStep}
          />
        )}
        {selectedSection === 'Taking Action' && (
          <TakingAction
            chapter={chapter}
            nextStep={nextStep}
            prevStep={prevStep}
            setModalState={setModalState}
          />
        )}
        {selectedSection !== 'Introduction' &&
          selectedSection !== 'Taking Action' && (
            <VizCanvas
              data={vizCanvasData}
              step={offset}
              selectedScenario={selectedScenario}
              setModalState={setModalState}
            />
          )}
      </Container>
    )
  }
}

export default Page
