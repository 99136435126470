import React from 'react'

const Svg24 = props => (
  <svg width={168} height={38} {...props}>
    <path
      d="M0 37h33.502a3.71 3.71 0 003.709-3.71h0V3.837A2.836 2.836 0 0140.047 1H168"
      stroke="#ED5835"
      fill="none"
      fillRule="evenodd"
    />
  </svg>
)

export default Svg24
