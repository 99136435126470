export default {
  scenarios: {
    Doomsday: {
      scenarioText: [
        'In the doomsday scenario, the shared and autonomous mobility market is largely left unchecked. AVs are gaining market share without a simultaneous increase in vehicle occupancy. In the absence of incentives to develop higher occupancy rides, private ride-hailing services dominate a modest shared mobility market.',
        'Autonomous driving is mainly developed to capitalise on cost saving opportunities for private mobility providers and higher income individuals willing to invest in a self-driving car as a revenue generating asset. Private ride-hailing providers compete on price and convenience.',
        'The increased convenience of car travel cause people to find their time commuting using a private car less burdensome, which shifts demand away from public transport and incentivises urban sprawl, further increasing reliance on car travel to access job centres in dense areas.',
        'Ridership in public transport will decrease, and investments in public transit will eventually accompany this trend.',
        'City authorities will be required to partly subsidise commuting costs for low income residents, making them less able to serve all residents with quality public spaces and services. In the absence of other policy interventions, the price that would have to be charged through road user charging to bring the number of vehicles on roads back to desired levels would be prohibitively high. Cities become more congested and more polluted.',
      ],
    },
    'Free flow mobility': {
      scenarioText: [
        'The free flow scenario assumes that car sharing is the pathway to autonomous vehicles.',
        'Shared mobility services gradually move toward higher occupancy services, thanks to a range of policy incentives, new purpose-built vehicles, better routing algorithms and a shifting user base with a higher propensity to use shared services.',
        'The reduction in costs associated with autonomy and high occupancy sharing generates additional demand for car travel.',
        'However this effect is offset by the reduction in cars that come with higher occupancy vehicles. In this scenario, saturation levels decrease from 101% in the peak time to 62% without having to implement a cordon, distance or time based road charge.',
        'This offers an opportunity to reclaim road space for more productive uses.',
      ],
      1: {
        caption: {
          text: 'Usership rather than ownership',
          x: 358.9437561035156,
          y: 300.39532470703125,
        },
        modalContent: [
          'City and transport authorities promote the importance of an innovation culture (by calling for innovation and making data and funding available) within the public in order to foster the support for publicly beneficial technological advancements such as demand responsive transit.',
          'Incentives to innovate in the public good, rather than shareholder value are provided. An in-depth understanding of the accrual of benefits from incentivisation and subsidy in the context of mobility markets is paramount to ensure the effective allocation of subsidy.',
          "Management initiatives such as workplace travel planning is seen an important vector to promote overall adoption of shared services for all user groups. Other 'soft' initiatives such as enforcing flexible working requirements are put in place to reduce the underlying need for car travel.",
          'Flexible dedicated lanes for higher occupancy services are implemented at peak traffic times on key access roads.',
          'Investigate the relative merits of the possibility of individuals renting out their own self-driving cars as part of a shared fleet.',
          'Develop machine learning algorithms to improve efficiency of routing and trip matching.',
        ],
      },
      2: {
        caption: {
          text: 'Autonomy for the public good',
          x: 462.51251220703125,
          y: 596.2047119140625,
        },
        modalContent: [
          'Empty vehicle miles in dense urban areas are priced or regulated.',
          'Autonomous vehicles in dense urban areas are mostly part of shared high occupancy fleets, well-regulated and zero emission.',
          'Maintenance and software upgrades are managed by professionals, and realise the promise of reductions in vehicles, parking and congestion, in line with broader policy trends to reduce the use of personal cars in dense urban areas.',
          'Prioritise AV development in places with the lowest connectivity levels. Private AVs are excluded from strategic zones.',
          'Review applicability of autonomous transit in high capacity corridors.',
        ],
      },
      3: {
        caption: {
          text: 'Fair pricing for better outcomes',
          x: 972.5531005859375,
          y: 206.7578125,
        },
        modalContent: [
          'Rather than propose a further technocratic recommendation, policymakers should explore new ways to engage stakeholders and the public on this topic, looking at a full range of potential options considering the major changes in road use and taxation that are inevitable.',
          'Road pricing can provide a more equitable, targeted approach by drawing on advances in understanding and an integrated approach to all mobility that technology can bring (i.e. Mobility as a Service platforms).',
          'A “delay-repay” mechanism where drivers would get a partial or full refund for journeys that take longer than expected changes perception of dynamic road charging as a real improvement.',
          'Directly reward, financially or otherwise, individuals who choose to walk or cycle in the future and to charge those who chose to drive. Developing a system that can evolve to meet the expectations, emerging business models, and necessary future outcomes.',
        ],
      },
      4: {
        caption: {
          text: 'Building our way out of congestion',
          x: 925.734375,
          y: 430.2109375,
        },
        modalContent: [
          'The way cities are built determines mobility needs and how they can be met. Development, urban design and public spaces, building and planning regulations, parking requirements, and other land use policies incentivise compact, accessible, liveable, and sustainable cities.',
          'Apply land use approaches to reduce the underlying need for commuting.',
          'Induced demand as a result of capacity increase is managed with the promotion of car-free developments through the planning system. The significant decrease in on-street parking that comes as a result of high adoption rates of shared mobility is used to increase housing density near employment centres and to improve the public realm experience in dense areas. Re-zoning of parking places has been integrated into local plans and sustainable funding models enable the investments required to adapt and transform urban spaces.',
          'Consider land use approaches to congestion management.',
          'Tax the use of kerb space.',
          'Ensure adequate, segregated infrastructure is provided to maximise a shift towards public and active travel.',
          'Design street policy frameworks that promote active mobility and a vibrant and attractive public realm, and a shift in vehicular travel demand, particularly for freight outside of peak hours.',
          'The planning system dictates that no new housing developments should be more than five minutes from a demand managed CAV enabled public transport.',
        ],
      },
    },
  },
}
