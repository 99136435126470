import React from 'react'
import FramerText from '../hocs/FramerText'

let cached = 0

const Svg85 = ({ scenario, ...rest }) => {
  const value = scenario ? scenario.value : 5
  const lastValue = cached

  cached = value

  return (
    <svg width={20} height={8} {...rest}>
      <text
        transform="translate(-1081 -177)"
        fill="#B77941"
        fillRule="evenodd"
        fontFamily="Montserrat-SemiBold, Montserrat"
        fontSize={10}
        fontWeight={500}
        letterSpacing={-0.229}
      >
        <FramerText
          value={value}
          lastValue={lastValue}
          order={scenario && scenario.order ? scenario.order : 1}
          x={1081}
          y={184}
        />
      </text>
    </svg>
  )
}
export default Svg85
