import React from 'react'
import styled from '@emotion/styled'
import { motion } from 'framer-motion'

import { ArrowLeft, ArrowRight } from '../Icons'
import { H3 } from '../../common/Styles'

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-contents: center;
`
const IconContainer = styled.div`
  margin-top: 0.25rem;
  display: flex;
  align-items: center;
  cursor: pointer;
`
const Title = styled(H3)`
  flex: 1 1 0;
  margin: 1rem 0.25rem;
`
const Icon = ({ position, onClick, visibility }) => {
  const Arrow = position === 'left' ? ArrowLeft : ArrowRight
  return (
    <IconContainer onClick={onClick} position={position}>
      <motion.div whileHover={{ scale: 1.2 }} whileTap={{ scale: 0.8 }}>
        <Arrow height={20} fill={'#333'} style={{ visibility }} />
      </motion.div>
    </IconContainer>
  )
}
const NavBtns = ({ title, size, step, nextStep, prevStep }) => {
  const prevShow = step > -1 ? 'visible' : 'hidden'
  const nextShow = step < size ? 'visible' : 'hidden'
  return (
    <Container>
      <Icon position={'left'} onClick={prevStep} visibility={prevShow} />
      <Title color={'black'}>{title}</Title>
      <Icon position={'right'} onClick={nextStep} visibility={nextShow} />
    </Container>
  )
}

export default NavBtns
