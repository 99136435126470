import React from 'react'
import FramerText from '../hocs/FramerText'

let cached = 0

const Svg81 = ({ scenario, ...rest }) => {
  const value = scenario ? scenario.value : 16.72
  const lastValue = cached

  cached = value

  return (
    <svg width={34} height={76} {...rest}>
      <g fill="none" fillRule="evenodd">
        <text
          fill="#122373"
          fontFamily="Montserrat-SemiBold, Montserrat"
          fontSize={10}
          fontWeight={500}
          letterSpacing={-0.229}
          transform="translate(1)"
        >
          <FramerText
            value={value}
            lastValue={lastValue}
            order={scenario && scenario.order ? scenario.order : 1}
            x={4}
            y={75}
          />
        </text>
        <path
          fill="#000"
          fillRule="nonzero"
          d="M7.71 3.85c.79.22 1.31.81 1.31 1.74 0 1.2-.91 1.89-2.68 1.89H3v-7h3.14c1.61 0 2.51.68 2.51 1.81 0 .76-.39 1.29-.94 1.56zM6.05 1.29H4v2.24h2.05c1.02 0 1.6-.38 1.6-1.12 0-.74-.58-1.12-1.6-1.12zm.25 5.38c1.11 0 1.71-.35 1.71-1.16 0-.81-.6-1.17-1.71-1.17H4v2.33h2.3zm8.691-1.81c0 .08-.01.2-.02.29h-4.3c.12.93.87 1.55 1.92 1.55.62 0 1.14-.21 1.53-.64l.53.62c-.48.56-1.21.86-2.09.86-1.71 0-2.85-1.13-2.85-2.71 0-1.57 1.13-2.7 2.66-2.7 1.53 0 2.62 1.1 2.62 2.73zm-2.62-1.92c-.92 0-1.6.62-1.7 1.52h3.4c-.1-.89-.77-1.52-1.7-1.52zM18.033.79c-.57 0-.86.32-.86.93v.46h1.54v.79h-1.52v4.51h-.96V2.97h-.9v-.79h.9v-.47c0-1.03.62-1.71 1.75-1.71.41 0 .81.1 1.08.31l-.29.73c-.2-.15-.46-.25-.74-.25zm3.551 6.75c-1.59 0-2.76-1.13-2.76-2.71s1.17-2.7 2.76-2.7c1.59 0 2.75 1.12 2.75 2.7s-1.16 2.71-2.75 2.71zm0-.84c1.02 0 1.78-.74 1.78-1.87 0-1.13-.76-1.86-1.78-1.86s-1.79.73-1.79 1.86c0 1.13.77 1.87 1.79 1.87zm4.772-3.63c.34-.62 1-.94 1.93-.94v.93c-.08-.01-.15-.01-.22-.01-1.03 0-1.67.63-1.67 1.79v2.64h-.96v-5.3h.92v.89zm7.621 1.79c0 .08-.01.2-.02.29h-4.3c.12.93.87 1.55 1.92 1.55.62 0 1.14-.21 1.53-.64l.53.62c-.48.56-1.21.86-2.09.86-1.71 0-2.85-1.13-2.85-2.71 0-1.57 1.13-2.7 2.66-2.7 1.53 0 2.62 1.1 2.62 2.73zm-2.62-1.92c-.92 0-1.6.62-1.7 1.52h3.4c-.1-.89-.77-1.52-1.7-1.52zM8.77 21.48H7.68l-1.51-2.15c-.14.01-.29.02-.44.02H4v2.13H3v-7h2.73c1.82 0 2.92.92 2.92 2.44 0 1.08-.56 1.86-1.54 2.21l1.66 2.35zM5.7 18.5c1.28 0 1.95-.58 1.95-1.58s-.67-1.57-1.95-1.57H4v3.15h1.7zm7.241 3.06c-1.84 0-2.96-1.07-2.96-3.1v-3.98h1v3.94c0 1.55.71 2.25 1.97 2.25s1.98-.7 1.98-2.25v-3.94h.97v3.98c0 2.03-1.11 3.1-2.96 3.1zm7.912 0c-2.12 0-3.7-1.51-3.7-3.58 0-2.07 1.58-3.58 3.71-3.58 1.08 0 2.02.37 2.66 1.09l-.65.63c-.54-.57-1.2-.83-1.97-.83-1.58 0-2.75 1.14-2.75 2.69s1.17 2.69 2.75 2.69c.77 0 1.43-.27 1.97-.84l.65.63c-.64.72-1.58 1.1-2.67 1.1z"
        />
        <path
          fill="#E6EFF2"
          d="M16 57.25h10c2.5-4.385 3.75-7.926 3.75-10.625 0-7.94-6.436-14.375-14.375-14.375C7.435 32.25 1 38.686 1 46.625c0 2.748 1.667 6.29 5 10.625h10z"
        />
        <path
          fill="#000"
          d="M16.99 47.192a.657.657 0 01-.464.173h-.002a.66.66 0 01-.467-.178.65.65 0 01-.193-.463.666.666 0 01.662-.664c.178 0 .341.069.468.195a.665.665 0 01-.004.937m5.56-7.284l-5.248 5.25c-.668-.324-1.52-.203-2.031.309a1.76 1.76 0 00-.521 1.253c0 .475.181.922.518 1.258.34.337.787.522 1.258.522s.917-.183 1.25-.518c.544-.544.67-1.37.33-2.052l5.23-5.234a.551.551 0 00.164-.393.529.529 0 00-.163-.392.566.566 0 00-.787-.003"
        />
        <path
          fill="#000"
          d="M15.909 31.524c-3.982 0-7.726 1.54-10.537 4.336A14.676 14.676 0 001 46.34c-.003 1.89 1.2 3.756 1.935 5.548l-.838.046.912.131a14.8 14.8 0 003.78 5.3c.106.097-.579.151-.422.135a.542.542 0 00.367-.17.533.533 0 00.15-.405.534.534 0 00-.186-.383A13.624 13.624 0 013.4 52.087l1.61-.665a.544.544 0 00.302-.728.567.567 0 00-.72-.298l-1.608.663a13.414 13.414 0 01-.849-4.173h1.75a.554.554 0 100-1.107h-1.75c.055-1.439.335-2.842.834-4.178l1.6.66a.566.566 0 00.426.008.553.553 0 00.32-.3.556.556 0 00-.3-.726l-1.628-.662a13.658 13.658 0 012.388-3.533l1.23 1.225c.1.1.242.16.389.162H7.4a.569.569 0 00.402-.162.561.561 0 00-.005-.783l-1.232-1.226a13.75 13.75 0 013.555-2.37l.672 1.614a.558.558 0 001.027-.005.566.566 0 000-.424l-.67-1.61a13.76 13.76 0 014.203-.82v1.734a.557.557 0 001.113 0v-1.734c1.443.053 2.855.33 4.202.82l-.675 1.617a.567.567 0 00.002.427.551.551 0 00.517.335.553.553 0 00.514-.34l.672-1.614a13.737 13.737 0 013.556 2.372l-1.24 1.233a.558.558 0 00.004.785.572.572 0 00.787-.003l1.24-1.233a13.681 13.681 0 012.388 3.54l-1.633.67a.558.558 0 00-.302.719.56.56 0 00.515.345.515.515 0 00.213-.04l1.63-.671c.497 1.335.777 2.74.832 4.18h-1.762a.555.555 0 000 1.11l1.762-.002a13.422 13.422 0 01-.854 4.174l-1.608-.663a.55.55 0 00-.428.003.55.55 0 000 1.02l1.622.665a13.776 13.776 0 01-3.3 4.454.545.545 0 00-.053.779c.104.12.256.187.42.187.142 0-.558-.05-.463-.14 1.624-1.45 3.764-3.284 4.619-5.304l.053-.116.015-.05a14.654 14.654 0 001.11-5.549 14.699 14.699 0 00-4.361-10.49 14.857 14.857 0 00-10.55-4.345z"
        />
        <path
          stroke="#000"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M24.74 57.15A14.142 14.142 0 0030.167 46c0-7.824-6.343-14.167-14.167-14.167S1.833 38.176 1.833 46c0 4.381 1.99 8.298 5.114 10.897"
        />
      </g>
    </svg>
  )
}
export default Svg81
