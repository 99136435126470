import React, { Component } from 'react'
import styled from '@emotion/styled'

import SideSection from '../../hocs/SideSection'
import Keyboard from '../KeyboardSvg'

const NavigationContainer = styled.div`
  display: flex;
  align-items: flex-end;
  padding-top: 1em;
`
const Text = styled.p`
  font-style: italic;
  font-weight: 500;
  font-size: 0.625rem;
  color: rgb(158, 158, 158);
  margin: 0 0 0 20px;
  width: 90px;
`

class IntroductionPanel extends Component {
  render() {
    return (
      <>
        {this.props.selectedChapter === 'Intro' && (
          <NavigationContainer>
            <Keyboard />
            <Text>Use the right key to navigate</Text>
          </NavigationContainer>
        )}
      </>
    )
  }
}

export default SideSection(IntroductionPanel)
