import React from 'react'
import styled from '@emotion/styled'
import { motion } from 'framer-motion'

import { ArrowRight } from '../Icons'

import { P, UL, LI } from '../../common/Styles'

import TakingActionSvg from '../TakingActionSvg'
import { MODAL_TYPES } from '../../constants/config'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 90%;
`
const Headline = styled.div`
  margin-top: 0.875rem;
`
const Title = styled.div`
  font-size: 2.5rem;
  font-weight: bold;
  line-height: 3.125;
`

const Policy = styled.div`
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  width: 50%;

  svg {
    margin-left: 0.5rem;
    display: block;
  }
`

const AnimatedArrowRight = () => (
  <motion.div whileHover={{ scale: 1.2 }} whileTap={{ scale: 0.8 }}>
    <ArrowRight height={20} fill={'#333'} />
  </motion.div>
)

const TakingAction = ({ setModalState }) => {
  const handlePolicyClick = scenarioStep => {
    setModalState({
      modalType: MODAL_TYPES.CAPTION_READ_MORE,
      options: {
        modalProps: {
          scenario: 'Free flow mobility',
          scenarioStep,
        },
      },
    })
  }

  return (
    <Container>
      <Headline>
        <Title>Taking action</Title>
      </Headline>

      <P>
        New forms of mobility supply and demand management can set the
        foundation for a more robust, resilient, fair and well-funded transport
        future. With the current myriad of technological evolutions, societal
        awareness and political willingness this fairer future is more
        achievable than ever before. Changing lifestyles, disruptive business
        models and emerging technologies mean the window of opportunity to
        develop a new approach to mobility management is within our grasp.
        Taking advantage of this opportunity to deliver transformational change
        will enable cities to ensure safe, equitable, efficient access
        throughout cities and enable continued growth. It will require taking a
        proactive approach, which will rely in part on:
      </P>

      <Policy onClick={() => handlePolicyClick(1)}>
        <P>
          <strong>Usership rather than ownership</strong>
        </P>
        <AnimatedArrowRight />
      </Policy>

      <Policy onClick={() => handlePolicyClick(2)}>
        <P>
          <strong>Autonomy for the public good</strong>
        </P>
        <AnimatedArrowRight />
      </Policy>

      <Policy onClick={() => handlePolicyClick(3)}>
        <P>
          <strong>Fair pricing for better outcomes</strong>
        </P>
        <AnimatedArrowRight />
      </Policy>

      <Policy onClick={() => handlePolicyClick(4)}>
        <P>
          <strong>Building our way out of congestion</strong>
        </P>
        <AnimatedArrowRight />
      </Policy>
    </Container>
  )
}

export default TakingAction
