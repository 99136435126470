import React from 'react'
import styled from '@emotion/styled'

import NavBtns from '../NavBtns'
import SideSection from '../../hocs/SideSection'
// import Doomsday from '../Scenarios/Doomsday'
// import FreeFlowingMobility from '../Scenarios/FreeFlowingMobility'
import SpeedDelayGraphic from '../SpeedDelayGraphic'

import { MODAL_TYPES } from '../../constants/config'
// const componentDict = {
//   Doomsday: Doomsday,
//   'Free flow mobility': FreeFlowingMobility,
// }

const ReadMore = styled.a`
  display: inline-block;
  margin-bottom: 1rem;
  font-size: 0.875rem;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`

const ScenariosPanel = ({
  selectedChapter,
  size,
  step,
  nextStep,
  prevStep,
  chapters,
  visData,
  setModalState,
}) => {
  const split = selectedChapter.split('_')
  const scenario = split[0]
  const scenarioStep = split[1]

  const handleClick = () => {
    setModalState({
      modalType: MODAL_TYPES.SCENARIO,
      options: {
        modalProps: {
          scenario,
        },
      },
    })
  }

  // const Component = componentDict[scenario]
  return (
    <>
      <NavBtns
        size={size}
        step={step}
        nextStep={nextStep}
        prevStep={prevStep}
        title={scenario}
      />
      <ReadMore role="button" onClick={handleClick}>
        Read more
      </ReadMore>

      <SpeedDelayGraphic
        visData={visData}
        chapters={chapters}
        chapter={selectedChapter}
      />
    </>
  )
}

export default SideSection(ScenariosPanel)
