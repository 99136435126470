import React from 'react'

const Svg65 = props => (
  <svg width={91} height={38} {...props}>
    <path
      d="M0 1h.502A3.71 3.71 0 014.21 4.71h0v29.454A2.836 2.836 0 007.047 37H91"
      stroke="#122373"
      fill="none"
      fillRule="evenodd"
    />
  </svg>
)

export default Svg65
