import React from 'react'

const Svg7 = props => (
  <svg width={56} height={78} {...props}>
    <path
      d="M0 77h21a6 6 0 006-6V7a6 6 0 016-6h23"
      stroke="#ED5835"
      fill="none"
      fillRule="evenodd"
    />
  </svg>
)

export default Svg7
