import React from 'react'

const Svg50 = props => (
  <svg width={33} height={38} {...props}>
    <path
      d="M22 1h10H14.048a2.836 2.836 0 00-2.837 2.836v29.455A3.71 3.71 0 017.501 37H0"
      stroke="#ED5835"
      fill="none"
      fillRule="evenodd"
    />
  </svg>
)

export default Svg50
