import React from 'react'

const Svg30 = props => (
  <svg width={135} height={38} {...props}>
    <path
      d="M0 1h.502A3.71 3.71 0 014.21 4.71h0v29.454A2.836 2.836 0 007.047 37H135"
      stroke="#ED5835"
      fill="none"
      fillRule="evenodd"
    />
  </svg>
)

export default Svg30
