import React from 'react'

const SvgCar = props => (
  <svg width={39} height={20} {...props}>
    <g fill="none" fillRule="evenodd">
      <path
        fill="#E3B5AC"
        d="M29.916 12.584a3.487 3.487 0 013.483 3.484 3.487 3.487 0 01-3.483 3.483 3.487 3.487 0 01-3.484-3.483 3.488 3.488 0 013.484-3.484m0 1.072a2.412 2.412 0 100 4.824 2.412 2.412 0 000-4.824M7.795 12.584a3.487 3.487 0 013.483 3.484 3.487 3.487 0 01-3.483 3.483 3.487 3.487 0 01-3.483-3.483 3.487 3.487 0 013.483-3.484m0 1.072a2.412 2.412 0 100 4.824 2.412 2.412 0 000-4.824"
      />
      <path
        fill="#FFF5EE"
        d="M31.819 9.09l-30-.909L4.546.908c5.908-.103 9.848-.103 11.818 0 1.97.104 3.788.407 5.455.91l10 7.272z"
      />
      <path
        stroke="#E3B5AC"
        strokeWidth={0.7}
        d="M8.28 1.033l1.097 8.933M19.28 1.033l1.097 8.933"
      />
      <path
        fill="#D26F60"
        d="M5.675 1.44c-.401.201-.897.898-1.674 2.278-.777 1.38-1.595 3.014-1.407 3.323.188.308.255.254 1.126.268.87.013 5.163.138 8.307.21l13.008.393c1.996-.001 4.006-.464 4.006-.464-2.716-2.037-6.717-5.127-8.647-5.61-1.93-.482-5.452-.56-6.306-.608-1.456-.081-8.011.009-8.413.21M.004 13.494c.009-.295.714-.49.714-.49-.196-2.002-.201-4.218.054-5.807C1.094 5.196 3.96 1.265 4.39.944 4.818.623 9.598.594 12.278.54c1.044 0 1.788.03 1.881.03 1.84.07 6.092.339 7.593.732 1.5.393 8.602 5.23 8.602 5.23.268.106.697.106 1.394.469.696.361 4.43 2.04 5.547 2.733 1.016.63 1.625 2.518 1.625 3.501 0 .982-.071 1.59-.608 2.555-.536.965-2.34 1.196-4.251 1.286a26.2 26.2 0 01-.257.011 4.024 4.024 0 00-3.889-5.038 4.024 4.024 0 00-3.871 5.099h-.595c-2.68 0-11.48-.326-13.275-.326-.124 0-.269-.002-.43-.005a4.024 4.024 0 00-3.949-4.77 4.024 4.024 0 00-4 4.428 2.897 2.897 0 01-.22-.025c-.527-.089-2.545-.67-3.072-1.116-.526-.446-.508-1.545-.5-1.84"
      />
    </g>
  </svg>
)

export default SvgCar
