import React from 'react'
import { motion } from 'framer-motion'
import { DURATION, DELAY } from '../constants/config'
import FramerText from '../hocs/FramerText'

let cached = 0
const total = 129.25

const Svg2 = ({ scenario, ...rest }) => {
  const value = scenario ? scenario.value : 110
  const fillHeight = Math.round(total * (value / 100))
  const lastValue = cached
  const lastFillHeight = Math.round(total * (lastValue / 100))

  cached = value

  const start = total - fillHeight > total ? total : total - fillHeight
  const end = total - lastFillHeight > total ? total : total - lastFillHeight

  const delay = scenario && scenario.order ? scenario.order * DELAY : 1 * DELAY

  return (
    <svg width={50} height={141} {...rest}>
      <defs>
        <rect id="2_svg__a" width={50} height={128} x={0} y={0} rx={5} />
      </defs>
      <g fill="none" fillRule="evenodd">
        <g transform="translate(0 13)">
          <mask id="2_svg__b" fill="#fff">
            <use xlinkHref="#2_svg__a" />
          </mask>
          <use fill="#C15727" xlinkHref="#2_svg__a" />
          <motion.path
            animate={{ d: `M-2-2h54v${start}H-2z` }}
            transition={{ duration: DURATION, delay }}
            fill="#122373"
            mask="url(#2_svg__b)"
            d={`M-2-2h54v${end}H-2z`}
          />
        </g>
        <path
          fill="#C15727"
          fillRule="nonzero"
          d="M3.09 8.08C2.02 8.08.97 7.72.41 7.2l.37-.78c.52.47 1.41.81 2.31.81 1.21 0 1.73-.47 1.73-1.08 0-1.71-4.24-.63-4.24-3.23 0-1.08.84-2 2.68-2 .82 0 1.67.22 2.25.62l-.33.8c-.61-.39-1.3-.57-1.92-.57-1.19 0-1.7.5-1.7 1.11 0 1.71 4.24.64 4.24 3.21 0 1.07-.86 1.99-2.71 1.99zm5.631-5.43c1.45 0 2.27.7 2.27 2.15V8h-.91v-.7c-.32.48-.91.76-1.74.76-1.2 0-1.96-.64-1.96-1.56 0-.85.55-1.55 2.13-1.55h1.52v-.19c0-.81-.47-1.28-1.42-1.28-.63 0-1.27.22-1.68.57l-.4-.72c.55-.44 1.34-.68 2.19-.68zm-.22 4.67c.72 0 1.29-.33 1.53-.94v-.74h-1.48c-.95 0-1.22.37-1.22.82 0 .53.44.86 1.17.86zM15.123 7l.3.69c-.29.25-.73.37-1.16.37-1.07 0-1.68-.59-1.68-1.66V3.49h-.9V2.7h.9V1.54h.96V2.7h1.52v.79h-1.52v2.87c0 .57.3.89.83.89.28 0 .55-.09.75-.25zm5.111-4.3h.96V8h-.91v-.8c-.39.55-1.05.86-1.8.86-1.37 0-2.28-.75-2.28-2.31V2.7h.96v2.94c0 1.04.52 1.56 1.43 1.56 1 0 1.64-.62 1.64-1.76V2.7zm3.382.89c.34-.62 1-.94 1.93-.94v.93c-.08-.01-.15-.01-.22-.01-1.03 0-1.67.63-1.67 1.79V8h-.96V2.7h.92v.89zm4.701-.94c1.45 0 2.27.7 2.27 2.15V8h-.91v-.7c-.32.48-.91.76-1.74.76-1.2 0-1.96-.64-1.96-1.56 0-.85.55-1.55 2.13-1.55h1.52v-.19c0-.81-.47-1.28-1.42-1.28-.63 0-1.27.22-1.68.57l-.4-.72c.55-.44 1.34-.68 2.19-.68zm-.22 4.67c.72 0 1.29-.33 1.53-.94v-.74h-1.48c-.95 0-1.22.37-1.22.82 0 .53.44.86 1.17.86zM34.72 7l.3.69c-.29.25-.73.37-1.16.37-1.07 0-1.68-.59-1.68-1.66V3.49h-.9V2.7h.9V1.54h.96V2.7h1.52v.79h-1.52v2.87c0 .57.3.89.83.89.28 0 .55-.09.75-.25zm1.611-5.32c-.37 0-.65-.27-.65-.62s.28-.63.65-.63.65.27.65.61c0 .36-.27.64-.65.64zM35.85 8V2.7h.96V8h-.96zm4.731.06c-1.59 0-2.76-1.13-2.76-2.71s1.17-2.7 2.76-2.7c1.59 0 2.75 1.12 2.75 2.7s-1.16 2.71-2.75 2.71zm0-.84c1.02 0 1.78-.74 1.78-1.87 0-1.13-.76-1.86-1.78-1.86s-1.79.73-1.79 1.86c0 1.13.77 1.87 1.79 1.87zm6.582-4.57c1.29 0 2.2.74 2.2 2.3V8h-.96V5.06c0-1.04-.52-1.55-1.43-1.55-1.02 0-1.68.61-1.68 1.76V8h-.96V2.7h.92v.8c.39-.54 1.07-.85 1.91-.85z"
        />
        <text
          fill="#FFF"
          fontFamily="Montserrat-Bold, Montserrat"
          fontSize={16}
          fontWeight="bold"
          letterSpacing={-0.366}
          textAnchor="middle"
          alignmentBaseline="middle"
        >
          <FramerText
            value={value}
            lastValue={lastValue}
            order={scenario && scenario.order ? scenario.order : 1}
            x={25}
            y={131}
            suffix={'%'}
          />
        </text>
      </g>
    </svg>
  )
}

export default Svg2
