import React from 'react'

const Svg22 = props => (
  <svg width={39} height={59} {...props}>
    <path
      d="M0 1h5.295a4.999 4.999 0 015 4.998s0 0 0 0v47a5 5 0 005 5h23"
      stroke="#ED5835"
      fill="none"
      fillRule="evenodd"
    />
  </svg>
)

export default Svg22
