import React from 'react'

const Svg31 = props => (
  <svg width={54} height={10} {...props}>
    <path
      d="M4.35 7.65c-2.12 0-3.7-1.51-3.7-3.58C.65 2 2.23.49 4.36.49c1.08 0 2.02.37 2.66 1.09l-.65.63c-.54-.57-1.2-.83-1.97-.83-1.58 0-2.75 1.14-2.75 2.69S2.82 6.76 4.4 6.76c.77 0 1.43-.27 1.97-.84l.65.63c-.64.72-1.58 1.1-2.67 1.1zm5.391-5.43c1.45 0 2.27.7 2.27 2.15v3.2h-.91v-.7c-.32.48-.91.76-1.74.76-1.2 0-1.96-.64-1.96-1.56 0-.85.55-1.55 2.13-1.55h1.52v-.19c0-.81-.47-1.28-1.42-1.28-.63 0-1.27.22-1.68.57l-.4-.72c.55-.44 1.34-.68 2.19-.68zm-.22 4.67c.72 0 1.29-.33 1.53-.94v-.74h-1.48c-.95 0-1.22.37-1.22.82 0 .53.44.86 1.17.86zm4.762-3.73c.34-.62 1-.94 1.93-.94v.93c-.08-.01-.15-.01-.22-.01-1.03 0-1.67.63-1.67 1.79v2.64h-.96v-5.3h.92v.89zm7.823-.94c1.55 0 2.68 1.08 2.68 2.7 0 1.63-1.13 2.71-2.68 2.71-.75 0-1.41-.29-1.85-.86v2.74h-.96V2.27h.92v.84c.43-.59 1.11-.89 1.89-.89zm-.08 4.57c1.02 0 1.79-.74 1.79-1.87 0-1.12-.77-1.86-1.79-1.86-1.01 0-1.78.74-1.78 1.86 0 1.13.77 1.87 1.78 1.87zm5.931.84c-1.59 0-2.76-1.13-2.76-2.71s1.17-2.7 2.76-2.7c1.59 0 2.75 1.12 2.75 2.7s-1.16 2.71-2.75 2.71zm0-.84c1.02 0 1.78-.74 1.78-1.87 0-1.13-.76-1.86-1.78-1.86s-1.79.73-1.79 1.86c0 1.13.77 1.87 1.79 1.87zm5.922.84c-1.59 0-2.76-1.13-2.76-2.71s1.17-2.7 2.76-2.7c1.59 0 2.75 1.12 2.75 2.7s-1.16 2.71-2.75 2.71zm0-.84c1.02 0 1.78-.74 1.78-1.87 0-1.13-.76-1.86-1.78-1.86s-1.79.73-1.79 1.86c0 1.13.77 1.87 1.79 1.87zm3.651.78V.15h.96v7.42h-.96zm2.841-6.32c-.37 0-.65-.27-.65-.62s.28-.63.65-.63.65.27.65.61c0 .36-.27.64-.65.64zm-.48 6.32v-5.3h.96v5.3h-.96zm5.192-5.35c1.29 0 2.2.74 2.2 2.3v3.05h-.96V4.63c0-1.04-.52-1.55-1.43-1.55-1.02 0-1.68.61-1.68 1.76v2.73h-.96v-5.3h.92v.8c.39-.54 1.07-.85 1.91-.85zm7.741.05h.91v4.58c0 1.87-.95 2.72-2.75 2.72-.97 0-1.95-.27-2.53-.79l.46-.74c.49.42 1.26.69 2.04.69 1.25 0 1.82-.58 1.82-1.78v-.42c-.46.55-1.15.82-1.91.82-1.53 0-2.69-1.04-2.69-2.57 0-1.53 1.16-2.56 2.69-2.56.79 0 1.51.29 1.96.87v-.82zm-1.85 4.24c1.06 0 1.82-.71 1.82-1.73 0-1.03-.76-1.72-1.82-1.72-1.07 0-1.83.69-1.83 1.72 0 1.02.76 1.73 1.83 1.73z"
      fill="#C15727"
      fillRule="nonzero"
    />
  </svg>
)

export default Svg31
