import React from 'react'
import { P } from '../../common/Styles'

export default {
  'Baseline occupancy': {
    name: (
      <React.Fragment>
        <h4>Mark Rowland</h4>
      </React.Fragment>
    ),
    email: (
      <React.Fragment>
        <a href="mailto:Mark.Rowland@arup.com">Mark.Rowland@arup.com</a>
      </React.Fragment>
    ),
    reference: (
      <React.Fragment>
        <P>
          Mark Rowland has over 12 years of professional experience in
          transport, including: network planning, engineering, safety,
          operations, technology, land-use and place integration. Mark has
          obtained technical excellence in transport and has challenged himself
          in other fields, such as: organisational governance and
          transformation.
        </P>

        <P>
          In recent years he has led on a number of future and intelligent
          mobility projects, including four Connected and Autonomous Vehicle
          Trials, advising Infrastructure Victoria on how to support and enable
          the safe and efficient operation of autonomous vehicles and electric
          vehicles, and is currently involved in the development and roll-out of
          Mobility as a Service in Australia.
        </P>
        <P>
          Mark is leading advocate for integrating Safe Systems thinking into
          strategic transport planning, in particular helping Governments to
          integrate safety into the Movement and Place planning for Cities.
        </P>
      </React.Fragment>
    ),
  },
  Demographics: {
    name: (
      <React.Fragment>
        <h4>Susan Claris</h4>
      </React.Fragment>
    ),
    email: (
      <React.Fragment>
        <a href="mailto:Susan.Claris@arup.com">Susan.Claris@arup.com</a>
      </React.Fragment>
    ),
    reference: (
      <React.Fragment>
        <P>
          Susan’s background is in planning and anthropology which is a good
          combination to understand the complex interrelationships that exist in
          cities. She has worked for Arup for more than twenty years. In recent
          years she has undertaken research on urbanisation for Arup's Drivers
          of Change programme and research on urban mobility for the World
          Economic Forum.
        </P>
        <P>
          Her current work is with local authorities looking at how transport
          can help shape more liveable towns and cities. She also works on
          identifying possible application of nudge theory to travel behaviour
          to support the use of sustainable modes (i.e. public transport,
          cycling, walking).
        </P>
      </React.Fragment>
    ),
  },
  'Shared mobility': {
    name: (
      <React.Fragment>
        <h4>Terry Lee-Williams</h4>
      </React.Fragment>
    ),
    email: null,
    reference: (
      <React.Fragment>
        <P>
          Terry Lee-Williams has worked across Australia, Asia and Europe in a
          wide variety of transport policy, strategy, infrastructure, services
          and technology roles as an advisor, civil servant, business owner and
          now Arup’s transport advisory leader for Europe. With a relentless
          focus on people and access to opportunity, equity of outcome and
          extracting value from public investment, he has led multi-billion
          dollar transport investment programmes, future transport planning and
          strategy, service procurement and implemented one of the world’s
          largest integrated ticketing systems.
        </P>
      </React.Fragment>
    ),
  },
  'Demand response': {
    name: (
      <React.Fragment>
        <h4>William Baumgardner</h4>
      </React.Fragment>
    ),
    email: (
      <React.Fragment>
        <a href="mailto:william.baumgardner@arup.com">
          william.baumgardner@arup.com
        </a>
      </React.Fragment>
    ),
    reference: (
      <React.Fragment>
        <P>
          Will Baumgardner leads Arup’s Transportation Consulting practice in
          San Francisco. He has a broad range of experience and typically leads
          challenging projects that require both planning and engineering
          skills.
        </P>
        <P>
          An engineer by training, he has complemented this technical background
          with experience on numerous, site, corridor, and regional planning
          efforts.He has successfully worked with Public Transit agencies to
          complete corridor and strategic planning efforts. He has experience
          planning for virtually all modes of public transport, from community
          buses to ferries to rail and bus rapid transit.He has a wealth of
          experience in the planning and design of intermodal passenger
          terminals. He has developed innovative solutions at facilities ranging
          from relatively modest park and rides to several of the busiest
          passenger stations and airports in North America.
        </P>
      </React.Fragment>
    ),
  },
  'Road user charging': {
    name: (
      <React.Fragment>
        <h4>Padraig Kenny</h4>
      </React.Fragment>
    ),
    email: (
      <React.Fragment>
        <a href="mailto:Padraig.kenny@arup.com">Padraig.kenny@arup.com</a>
      </React.Fragment>
    ),
    reference: (
      <React.Fragment>
        <P>
          Global leader of Arup's Road Pricing business and the Europe leader
          for Digital Services with responsibility for Arup's portfolio of
          advisory services on the use of digital technologies in transport,
          property and industry. Padraig has worked in digital technology for 40
          years, spanning software, telecoms, telematics and intelligent
          transport systems in the US and in Europe's busiest passenger stations
          and airports in North America.
        </P>
      </React.Fragment>
    ),
  },
  'Road capacity': {
    name: (
      <React.Fragment>
        <h4>Tim Gammons</h4>
      </React.Fragment>
    ),
    email: (
      <React.Fragment>
        <a href="mailto:Tim.Gammons@arup.com">Tim.Gammons@arup.com</a>
      </React.Fragment>
    ),
    reference: (
      <React.Fragment>
        <P>
          Tim is a Director of Arup’s Intelligent Transport Solutions Business
          and regularly promotes Arup’s innovative approach to transport
          solutions at International Conferences. He has over 25 years’
          experience in the planning, design, operation and project management
          of Intelligent Transport Systems and control systems for the
          inter-urban and urban environment. In particular this experience
          includes strategy development, feasibility studies, business cases,
          research and development, contract administration and programme
          control.
        </P>
        <P>
          He spent several years in research and development of control systems
          before moving to the UK Government’s Highways Agency. Since 1995 he
          has been consulting on ITS project planning, implementation and
          operation in the UK and overseas.
        </P>
      </React.Fragment>
    ),
  },
  'Self-driving vehicles': {
    name: (
      <React.Fragment>
        <h4>John McCarthy</h4>
      </React.Fragment>
    ),
    email: (
      <React.Fragment>
        <a href="mailto:John.McCarthy@arup.com">John.McCarthy@arup.com</a>
      </React.Fragment>
    ),
    reference: (
      <React.Fragment>
        <P>
          John is responsible for leading Arup’s Intelligent Mobility activity
          both nationally and internationally. Intelligent Mobility is about
          understanding how disruptive technology will impact how Governments,
          Cities and People engage with transport and mobility choices. This is
          a hugely exciting and challenging role, one that will examine the role
          of new technologies in society, such as Connected and Autonomous
          Vehicles and Mobility as a Service whilst also developing effective
          Intelligent and Co-Operative Transport Systems.
        </P>
      </React.Fragment>
    ),
  },
  'Induced demand': {
    name: (
      <React.Fragment>
        <h4>Richard de Cani</h4>
      </React.Fragment>
    ),
    email: (
      <React.Fragment>
        <a href="mailto:Richard.DeCani@arup.com">Richard.DeCani@arup.com</a>
      </React.Fragment>
    ),
    reference: (
      <React.Fragment>
        <P>
          Richard joined Arup from Transport for London where he was the
          Managing Director of Planning. TfL is the largest integrated transport
          authority in the world, responsible for the management and operation
          of a variety of transport services including road, rail, tube, bus and
          river and for developing and implementing the strategic transport plan
          for the city.
        </P>
        <P>
          In his time at TfL Richard was instrumental in establishing a long
          term strategic plan for transport in London that has influenced the
          development of the city and delivered major changes in patterns of
          movement. Richard led all TfL's planning activities including: demand
          forecasting, monitoring and research; development of transport
          strategies; environmental policies such as ULEZ; new policy areas such
          as transport and health; project appraisal and business case
          development; assessment of wider economic benefits; project
          development and consents; advocate at Public Inquiries; area based
          studies and master plans; and major planning applications and funding.
        </P>
        <P>
          As a qualified town planner and transport planner, Richard brings over
          23 years' experience of all aspects of planning, particularly those
          relating to the management and development of transport and
          infrastructure in cities and urban areas. Richard started his career
          at Arup as a graduate.
        </P>
      </React.Fragment>
    ),
  },
}
