import React, { Component } from 'react'
import styled from '@emotion/styled'

import Introduction from './IntroductionPanel'
import UnderstandingCongestion from './UnderstandingCongestionPanel'
import Scenarios from './ScenariosPanel'
import TakingAction from './TakingActionPanel'
// import ReferencesIcon from '../ReferencesIcon'
import ArupLogo from '../ArupLogo'
import referencesContent from '../References/Content'

import { WIDTH, structure, contents, MODAL_TYPES } from '../../constants/config'

const Container = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  width: ${props => props.width}px;
  height: 100%;
  padding: 0 2rem 1rem;
  display: flex;
  flex-direction: column;
`

const FlexGrow = styled.div`
  /* To match margin of top bar */
  padding-top: 4rem;
  flex: 1;
  overflow-y: auto;
`
//this uses flexbox as it will also be for the twitter share icon
// const IconsContainer = styled.div`
//   display: flex;
//   justify-content: flex-end;
//   padding-bottom: 1rem;
//   cursor: pointer;
// `
const LogoContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 1rem 0;
`
const items = [
  { El: Introduction, title: 'Introduction', key: 'I', quickStep: 0 },
  {
    El: UnderstandingCongestion,
    title: 'Understanding Congestion',
    key: 'C',
    quickStep: contents.indexOf('Congestion'),
  },
  {
    El: Scenarios,
    title: 'Scenarios',
    key: 'S',
    quickStep: contents.indexOf('Doomsday_1'),
  },
  {
    El: TakingAction,
    title: 'Taking Action',
    key: 'A',
    quickStep: contents.indexOf('Taking Action'),
  },
]

class SidePanel extends Component {
  // showReferencesModal = () => {
  //   const { setModalState, chapter } = this.props

  //   setModalState({
  //     modalType: MODAL_TYPES.REFERENCES,
  //     options: {
  //       modalProps: {
  //         chapter,
  //       },
  //     },
  //   })
  // }

  render() {
    const {
      chapter,
      selectedSection,
      size,
      step,
      nextStep,
      prevStep,
      toStep,
      setModalState,
      visData,
    } = this.props

    return (
      <Container width={WIDTH}>
        <FlexGrow>
          {items.map(f => {
            const { El, title, key, quickStep } = f
            const selected = selectedSection === title
            const chapters = structure[title]
            return (
              <El
                key={`${key}-sidesection`}
                title={title}
                selected={selected}
                chapters={chapters}
                selectedChapter={chapter}
                size={size}
                step={step}
                nextStep={nextStep}
                prevStep={prevStep}
                quickStep={quickStep}
                toStep={toStep}
                visData={visData}
                setModalState={setModalState}
              />
            )
          })}
        </FlexGrow>
        {/* selectedSection === 'Understanding Congestion' &&
          referencesContent[chapter] && (
            <IconsContainer>
              <div onClick={this.showReferencesModal}>
                <ReferencesIcon />
              </div>
            </IconsContainer>
          ) */}
        <LogoContainer>
          <ArupLogo />
        </LogoContainer>
      </Container>
    )
  }
}
export default SidePanel
