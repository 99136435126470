import React from 'react'

const Svg18 = props => (
  <svg width={43} height={9} {...props}>
    <path
      d="M7.5 8h-.96l-.01-5.1L4 7.15h-.46L1.01 2.93V8H.05V1h.82l2.92 4.92L6.67 1h.82l.01 7zm2.011-6.32c-.37 0-.65-.27-.65-.62s.28-.63.65-.63.65.27.65.61c0 .36-.27.64-.65.64zM9.031 8V2.7h.96V8h-.96zm2.362 0V.58h.96V8h-.96zm2.361 0V.58h.96V8h-.96zm7.152-2.62c0 .08-.01.2-.02.29h-4.3c.12.93.87 1.55 1.92 1.55.62 0 1.14-.21 1.53-.64l.53.62c-.48.56-1.21.86-2.09.86-1.71 0-2.85-1.13-2.85-2.71 0-1.57 1.13-2.7 2.66-2.7 1.53 0 2.62 1.1 2.62 2.73zm-2.62-1.92c-.92 0-1.6.62-1.7 1.52h3.4c-.1-.89-.77-1.52-1.7-1.52zm6.351-.81c1.29 0 2.2.74 2.2 2.3V8h-.96V5.06c0-1.04-.52-1.55-1.43-1.55-1.02 0-1.68.61-1.68 1.76V8h-.96V2.7h.92v.8c.39-.54 1.07-.85 1.91-.85zm4.032-.97c-.37 0-.65-.27-.65-.62s.28-.63.65-.63.65.27.65.61c0 .36-.27.64-.65.64zM28.189 8V2.7h.96V8h-.96zm4.291-5.35c1.45 0 2.27.7 2.27 2.15V8h-.91v-.7c-.32.48-.91.76-1.74.76-1.2 0-1.96-.64-1.96-1.56 0-.85.55-1.55 2.13-1.55h1.52v-.19c0-.81-.47-1.28-1.42-1.28-.63 0-1.27.22-1.68.57l-.4-.72c.55-.44 1.34-.68 2.19-.68zm-.22 4.67c.72 0 1.29-.33 1.53-.94v-.74h-1.48c-.95 0-1.22.37-1.22.82 0 .53.44.86 1.17.86zm3.841.68V.58h.96V8h-.96zm3.882.06c-.88 0-1.75-.27-2.19-.62l.4-.76c.45.32 1.17.55 1.86.55.89 0 1.26-.27 1.26-.72 0-1.19-3.35-.16-3.35-2.27 0-.95.85-1.59 2.21-1.59.69 0 1.47.18 1.93.48l-.41.76c-.48-.31-1.01-.42-1.53-.42-.84 0-1.25.31-1.25.73 0 1.25 3.36.23 3.36 2.29 0 .96-.88 1.57-2.29 1.57z"
      fill="#C15727"
      fillRule="nonzero"
    />
  </svg>
)

export default Svg18
