import React from 'react'

const Svg44 = props => (
  <svg width={38} height={8} {...props}>
    <path
      d="M6.14.57h1l-3.07 7h-.99L0 .57h1.08L3.6 6.32 6.14.57zm2.851 1.65c1.45 0 2.27.7 2.27 2.15v3.2h-.91v-.7c-.32.48-.91.76-1.74.76-1.2 0-1.96-.64-1.96-1.56 0-.85.55-1.55 2.13-1.55h1.52v-.19c0-.81-.47-1.28-1.42-1.28-.63 0-1.27.22-1.68.57l-.4-.72c.55-.44 1.34-.68 2.19-.68zm-.22 4.67c.72 0 1.29-.33 1.53-.94v-.74h-1.48c-.95 0-1.22.37-1.22.82 0 .53.44.86 1.17.86zm4.762-3.73c.34-.62 1-.94 1.93-.94v.93c-.08-.01-.15-.01-.22-.01-1.03 0-1.67.63-1.67 1.79v2.64h-.96v-5.3h.92v.89zm3.161-1.91c-.37 0-.65-.27-.65-.62s.28-.63.65-.63.65.27.65.61c0 .36-.27.64-.65.64zm-.48 6.32v-5.3h.96v5.3h-.96zm4.292-5.35c1.45 0 2.27.7 2.27 2.15v3.2h-.91v-.7c-.32.48-.91.76-1.74.76-1.2 0-1.96-.64-1.96-1.56 0-.85.55-1.55 2.13-1.55h1.52v-.19c0-.81-.47-1.28-1.42-1.28-.63 0-1.27.22-1.68.57l-.4-.72c.55-.44 1.34-.68 2.19-.68zm-.22 4.67c.72 0 1.29-.33 1.53-.94v-.74h-1.48c-.95 0-1.22.37-1.22.82 0 .53.44.86 1.17.86zm6.651-4.67c1.55 0 2.68 1.08 2.68 2.7s-1.13 2.71-2.68 2.71c-.78 0-1.46-.3-1.89-.9v.84h-.92V.15h.96v2.92c.44-.57 1.1-.85 1.85-.85zm-.08 4.57c1.02 0 1.79-.74 1.79-1.87 0-1.13-.77-1.86-1.79-1.86-1.01 0-1.78.73-1.78 1.86 0 1.13.77 1.87 1.78 1.87zm3.662.78V.15h.96v7.42h-.96zm7.151-2.62c0 .08-.01.2-.02.29h-4.3c.12.93.87 1.55 1.92 1.55.62 0 1.14-.21 1.53-.64l.53.62c-.48.56-1.21.86-2.09.86-1.71 0-2.85-1.13-2.85-2.71 0-1.57 1.13-2.7 2.66-2.7 1.53 0 2.62 1.1 2.62 2.73zm-2.62-1.92c-.92 0-1.6.62-1.7 1.52h3.4c-.1-.89-.77-1.52-1.7-1.52z"
      fill="#B77941"
      fillRule="nonzero"
    />
  </svg>
)

export default Svg44
