import hotkeys from 'hotkeys-js'
import { useCallback, useEffect } from 'react'

// Ensure keys can't be used to navigate if disabled condition is true
export default (keys, callback, disabled, deps = []) => {
  const memoisedCallback = useCallback(callback, deps)

  useEffect(() => {
    if (!disabled) {
      hotkeys(keys, memoisedCallback)
    }

    return () => hotkeys.unbind(keys)
  }, [memoisedCallback, disabled])
}
