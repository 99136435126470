import React from 'react'

const Svg79 = props => (
  <svg width={113} height={93} {...props}>
    <path
      d="M0 91.668l105.982.314a6 6 0 006.018-6V0h0"
      stroke="#122373"
      fill="none"
      fillRule="evenodd"
    />
  </svg>
)

export default Svg79
