import React from 'react'

const Svg74 = props => (
  <svg width={80} height={23} {...props}>
    <path
      d="M0 21.668h73.105a6 6 0 006-6.04L79 0h0"
      stroke="#122373"
      fill="none"
      fillRule="evenodd"
    />
  </svg>
)

export default Svg74
