import React from 'react'

const Svg13 = props => (
  <svg width={32} height={101} {...props}>
    <g fill="none" fillRule="evenodd">
      <path
        fill="#FFE7D7"
        d="M16 2c8.837 0 16 7.163 16 16v8H0v-8C0 9.163 7.163 2 16 2z"
      />
      <rect
        width={30}
        height={99}
        x={1}
        y={1}
        stroke="#231F20"
        strokeWidth={2}
        rx={15}
      />
      <text
        fill="#000"
        fontFamily="Montserrat-SemiBold, Montserrat"
        fontSize={10}
        fontWeight={500}
        letterSpacing={-0.229}
      >
        <tspan x={8} y={19}>
          {'15%'}
        </tspan>
      </text>
    </g>
  </svg>
)

export default Svg13
