import React from 'react'
import styled from '@emotion/styled'

import TopBar from '../TopBar'
import Intro from './Intro'
import AutonomousMobility from './AutonomousMobility'
import SharedMobility from './SharedMobility'

import { WIDTH } from '../../constants/config'

const Container = styled.div`
  margin-left: ${props => props.width}px;
  margin-right: ${props => props.width}px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`
const Inner = styled.div`
  width: 655px;
`

const Introduction = ({ chapter, nextStep, prevStep }) => {
  return (
    <Container width={WIDTH}>
      <Inner>
        <TopBar chapter={chapter} nextStep={nextStep} prevStep={prevStep} />
        {chapter === 'Intro' && <Intro />}
        {chapter === 'Autonomous Mobility' && <AutonomousMobility />}
        {chapter === 'Shared Mobility' && <SharedMobility />}
      </Inner>
    </Container>
  )
}

export default Introduction
