import React from 'react'

const Svg78 = props => (
  <svg width={31} height={78} {...props}>
    <path
      d="M30 0l-.163 67.703v3.46A5.837 5.837 0 0124 77h0H0"
      stroke="#ED5835"
      fill="none"
      fillRule="evenodd"
    />
  </svg>
)

export default Svg78
