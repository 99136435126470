import React from 'react'
import styled from '@emotion/styled'

import { P } from '../../common/Styles'

const Card = styled.div``

const Badge = styled.div`
  padding: 0.2rem 0.5rem;
  border-radius: 20px;
  background-color: ${props => props.theme.green};
  display: inline-block;
  color: ${props => props.theme.white};
  font-weight: bold;
  font-size: 0.875rem;
`

const Title = styled.h3`
  font-size: 0.875rem;
  margin: 4px 0 3px;
`

const BadgeCard = ({ title, body }) => (
  <Card>
    <Badge>{title}</Badge>
    <P>{body}</P>
  </Card>
)

export default BadgeCard
