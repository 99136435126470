import React from 'react'

const Svg19 = props => (
  <svg width={48} height={74} {...props}>
    <g stroke="#ED5835" fill="none" fillRule="evenodd">
      <path d="M16 37h.21a4 4 0 014.004 4v28.996a3 3 0 003 3h24" />
      <path d="M0 1h1.247a3.999 3.999 0 014 4v29a3 3 0 003 3H17 0l17.222.01h0-9a3 3 0 00-3 3v30a3 3 0 01-3.006 2.995L0 73" />
    </g>
  </svg>
)

export default Svg19
