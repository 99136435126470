import React from 'react'

const Svg59 = props => (
  <svg width={263} height={2} {...props}>
    <path
      d="M0 1l262.5.5"
      stroke="#353D91"
      fill="none"
      fillRule="evenodd"
      strokeLinecap="square"
    />
  </svg>
)

export default Svg59
