export default {
  Congestion:
    'Congestion is defined as a relationship between road capacity and demand, both measured in vehicles per hour.',
  'Saturation level':
    'The ratio between road capacity and demand is the saturation level. If the ratio is above 70%, a road is no longer a ‘free-flow’ conditions, average speed will fall and delays will rise.',
  Demand:
    'Demand on a road is measured in vehicles per hour – not people. Moving more people with less vehicle is a central challenge.',
  'City and time of day':
    'People will react differently to new mobility technologies depending on the city they live in. Factors such as density, walkability, and quality of the public transport network will have a huge influence on how car users are likely to adapt their behaviours. Demand conditions are different in each city, at each times of the day.',
  'Baseline occupancy':
    'The occupancy, or load factor, is the number of people in each vehicle. This is a key area to focus on when thinking about better utilising our roads, especially for commuters.',
  Demographics:
    'In addition to the city they live in, car drivers of different age and income groups will react differently to the introduction of new mobility technologies. Wealthy Millenials are more likely to use shared services compared to low income baby boomers, and they will have a different response to the changing costs of car travel, for example.',
  'Shared mobility':
    'Three options of shared mobility will have different implications on future traffic patterns. Car clubs, ride-hailing, and ride-sharing will impact occupancy per vehicle and the cost of travelling by car in different ways.',
  'New Occupancy':
    'Moving more people with less vehicles is a key objective that shared mobility can potentially help to realise.',
  'Cost of car travel':
    'Different adoption levels of autonomy and shared mobility will have different implications on the cost of car travel – whether that is through the cost of the vehicle itself, its insurance, fuel, parking, tolls, hail fares, or the cost of time spent travelling – different adoption rates of each new mobility technology will generate a new cost of travel for car drivers.',
  'Demand response':
    'If the cost of car travel decreases, we can expect demand to increase, and inversely. Users will be more responsive to changes in costs that need to be incurred repeatedly (fuel) rather than once a year (insurance). Users will also react differently based on the city they live in and their individual characteristics.',
  'Road user charging':
    'Fair, equitable and dynamic pricing structure should be investigated to bring demand back to desired levels in cases where congestion persist.',
  'New demand':
    'The combined action of impact variables just discussed generates new demand.',
  'Road capacity':
    'The designed road capacity is usually expressed in vehicles per hour. The number of lanes, their width and the quality of the road will determine its capacity.',
  'Self-driving vehicles':
    'Autonomous vehicles on their own will lower the cost of car travel and create new demand. Different levels of automation will impact costs differently. For example, even at partial automation (level 3) the cost associated with the time spent in the vehicle can be removed in bumper to bumper traffic situations.',
  'Headway impact':
    'Self-driving vehicles have the potential to increase the efficiency of traffic as increased coordination will reduce the safe distance required between vehicles, whilst achieving similar or greater speeds. This effect is translated into additional capacity on a road, and this is predicted to start happening at 40% adoption of autonomy. Trials and simulations suggest that an additional 25% capacity could be created with 100% adoption of autonomy.',
  'Induced demand':
    'In the long-term, the capacity increase from the headway impact resulting from AV adoption will eventually be caught up by demand. Increases in capacity create the conditions for additional demand. This happens through people shifting their routes, changing modes, or through long-run land use changes which create new and longer trips.',
  'New Capacity':
    'The combined action of impact variables just discussed generates new capacity. The ratio of new demand to new capacity determines the traffic conditions that are achieved in a given scenario.',
}
