import React from 'react'

const Svg39 = props => (
  <svg width={75} height={38} {...props}>
    <path
      d="M0 37h29.502a3.71 3.71 0 003.709-3.71h0V3.837A2.836 2.836 0 0136.047 1H75"
      stroke="#B77941"
      fill="none"
      fillRule="evenodd"
    />
  </svg>
)

export default Svg39
