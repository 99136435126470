import React, { useState, useEffect } from 'react'
import styled from '@emotion/styled'

import SvgItem from './SvgItem'
import ScenarioSvgItem from './ScenarioSvgItem'
import City from './City'
import Car from './Car'
import Road from './Road'
import Circle from './Circle'
import { P } from '../../common/Styles'
import useResponsive from '../../hooks/useResponsive'
import {
  WIDTH,
  SVGDIMENSIONS,
  SCENARIO_SPEEDS,
  CITYWIDTH,
  MODAL_TYPES,
} from '../../constants/config'
import SVGCaption from '../SVGCaption'
import TEXT_CONTENT from '../../constants/textContent'

const SVG_CTX = require.context('../../svgs/', true, /js$/)

const svgs = SVG_CTX.keys().reduce((svgs, file) => {
  const Svg = SVG_CTX(file).default
  const label = file.slice(2, -3) // strip './' and '.js'
  svgs[label] = Svg
  return svgs
}, {})

const Container = styled.div`
  height: 100%;
  width: 100%;
  overflow: hidden;
`

// N.B. This cache assumes that next fresh value is different to cache, as we have removed
// adjacent duplicates from data in script
const scenarioValueCache = {}

const VizCanvas = ({ data, step, selectedScenario, setModalState }) => {
  const [width, height] = useResponsive()
  const [svgMounted, setSvgMounted] = useState(false)

  useEffect(() => setSvgMounted(true), [])

  // find circle values
  const circleNumbers = [62, 64, 66]
  const circleData = data.filter(f => circleNumbers.includes(f.id))

  // Some scenarios have _<number> suffix
  const key = selectedScenario.replace(/_\d+$/, '')
  const { city, car, road, size, carDelay } = SCENARIO_SPEEDS[key]

  const [scenario, scenarioStep] = selectedScenario.split('_')
  let caption = null

  if (
    TEXT_CONTENT.scenarios[scenario] &&
    TEXT_CONTENT.scenarios[scenario][scenarioStep]
  ) {
    caption = TEXT_CONTENT.scenarios[scenario][scenarioStep].caption
  }

  const handleCaptionClick = () => {
    setModalState({
      modalType: MODAL_TYPES.CAPTION_READ_MORE,
      options: {
        modalProps: {
          scenario,
          scenarioStep,
        },
      },
    })
  }

  console.log(selectedScenario, step)

  return (
    <Container>
      <svg
        onClick={e => {
          // N.B. temporary util for caption positioning exercise
          const svg = document.getElementById('viz')
          const val = screenToSVG(e.nativeEvent.clientX, e.nativeEvent.clientY)

          console.log(val)

          function screenToSVG(screenX, screenY) {
            var p = svg.createSVGPoint()
            p.x = screenX
            p.y = screenY
            return p.matrixTransform(svg.getScreenCTM().inverse())
          }
        }}
        id="viz"
        width={width - WIDTH}
        height={height}
        viewBox={`0 0 ${SVGDIMENSIONS.width} ${SVGDIMENSIONS.height}`}
      >
        <defs>
          {/* Setup grayscale filter */}
          <filter id="grayscale">
            <feColorMatrix type="saturate" values="0.10" />
          </filter>

          {/* N.B. height is y value for road + height of road svg */}
          <clipPath id="cityroadclip">
            <rect width={CITYWIDTH} height={208} x={0} y={0} />
          </clipPath>
        </defs>

        {/*
          N.B. key is important here as we want to re-render children
          of this <g> on key change (clean slate).
        */}
        <g
          key={key}
          transform={`translate(198, 265)`}
          clipPath="url(#cityroadclip)"
        >
          <City x={0} y={0} speed={city} />
          <Road x={0} y={186} speed={road} />
          <Car x={0} y={186} speed={car} delay={carDelay} size={size} />
        </g>

        {data.map(f => {
          const { page, id } = f
          // TODO: clear up understanding of this, can we just start step at 1? check rest of codebase for usage
          const show = page <= step + 1 // step starts at 0 but page starts at 1
          if (show && selectedScenario === 'Default') {
            return (
              <SvgItem
                Svg={svgs[id]}
                key={`vis-${id}`}
                data={f}
                shouldAnimate={page === step + 1}
              />
            )
          } else if (selectedScenario !== 'Default') {
            let scenarioData = null
            let isChanged = false

            if (f.scenario) {
              // Look for a scenario value
              if (f.scenario[selectedScenario] !== undefined) {
                scenarioData = f.scenario[selectedScenario]
                // Update cached value with latest fresh value
                scenarioValueCache[id] = f.scenario[selectedScenario]
                // Let svg component it should highlight this component
                isChanged = true
              } else {
                // Default to cached value
                scenarioData = scenarioValueCache[id]
              }
            }

            return (
              <ScenarioSvgItem
                Svg={svgs[id]}
                selectedScenario={selectedScenario}
                key={`vis-${id}`}
                data={f}
                scenario={scenarioData}
                isChanged={isChanged}
              />
            )
          } else {
            return null
          }
        })}
        <Circle
          show={circleData[0].page <= step + 1}
          selectedScenario={selectedScenario}
          data={circleData}
        />

        {svgMounted && caption && (
          <SVGCaption x={caption.x} y={caption.y}>
            <P>{caption.text}</P>
            <a role="button" onClick={handleCaptionClick}>
              Read more
            </a>
          </SVGCaption>
        )}
      </svg>
    </Container>
  )
}

export default VizCanvas
