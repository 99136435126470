import React from 'react'

const Svg9 = props => (
  <svg width={59} height={56} {...props}>
    <path
      d="M0 55h19-2 8.041a4.255 4.255 0 004.255-4.255V6.564A5.564 5.564 0 0134.86 1H37h-1 23"
      stroke="#ED5835"
      fill="none"
      fillRule="evenodd"
    />
  </svg>
)

export default Svg9
