import React from 'react'

const Svg53 = props => (
  <svg width={416} height={72} {...props}>
    <path
      d="M1.03 72L1.003 7.436A6.433 6.433 0 017.434 1h.003s0 0 0 0H409.36c3.422 0 5.866 2.774 5.866 6.196V37.46"
      stroke="#ED5835"
      fill="none"
      fillRule="evenodd"
    />
  </svg>
)

export default Svg53
