import React from 'react'
import styled from '@emotion/styled'
import { motion } from 'framer-motion'

import { DURATION } from '../../constants/config'
import { checkNested } from '../../utils'

const G = styled.g`
  opacity: 0;
  animation: appear 1s linear forwards;
  animation-delay: ${props => props.delay}s;

  @keyframes appear {
    to {
      opacity: 1;
    }
  }
`
const original = { x: 529, y: 551 }

let cached = { ...original }

const xOffset = 60
const yOffset = 14

const Circle = ({ data, selectedScenario, show }) => {
  const radius = 15

  if (!show) return null

  if (selectedScenario !== 'Default') {
    const found = data.find(
      f => checkNested(f, `scenario.${selectedScenario}.value`) === 'Yes'
    )
    const newxy = found ? { x: found.x, y: found.y } : cached
    const lastxy = cached

    cached = newxy

    return (
      <motion.circle
        animate={{ cx: newxy.x + xOffset, cy: newxy.y + yOffset }}
        transition={{ duration: DURATION }}
        cx={lastxy.x + xOffset}
        cy={lastxy.y + yOffset}
        r={radius}
        fill="rgb(105, 161, 172)"
        stroke="#333"
        strokeWidth={2}
      />
    )
  } else {
    const order = Math.max(...data.map(f => f.order)) + 1
    const delay = (order - 1) * 1
    return (
      <G delay={delay}>
        <circle
          cx={original.x + xOffset}
          cy={original.y + yOffset}
          r={radius}
          fill="rgb(105, 161, 172)"
          stroke="#333"
          strokeWidth={2}
        />
      </G>
    )
  }
}
export default Circle
