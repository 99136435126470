import React, { useMemo } from 'react'
import styled from '@emotion/styled/macro'

const Table = styled.table`
  border-collapse: collapse;
  width: 100%;

  th {
    padding-bottom: 0.25rem;
  }

  th,
  td {
    font-weight: normal;
    text-align: center;
  }

  tr td {
    font-size: 0.875rem;
    font-weight: bold;
    padding: 0.5rem 0;
  }

  th,
  tr td:first-of-type {
    font-size: 0.625rem;
  }

  tr th:last-of-type,
  tr td:last-of-type {
    color: rgb(53, 61, 145);
  }

  tbody {
    background: linear-gradient(
      -180deg,
      rgb(230, 239, 242) 0%,
      rgb(248, 244, 238) 100%
    );
  }

  /* Give the speed and delay rows rounded corners */
  /* N.B. can't do this for <tr>s, only individual <td>s */
  tbody tr:first-of-type {
    border-bottom: 4px solid #f8f8f1;

    td:first-of-type {
      border-top-left-radius: 4px;
    }
    td:last-of-type {
      border-top-right-radius: 4px;
    }
  }

  tbody tr:last-of-type {
    td:first-of-type {
      border-bottom-left-radius: 4px;
    }
    td:last-of-type {
      border-bottom-right-radius: 4px;
    }
  }
`

const data = {
  avgSpeedBefore: {},
  avgSpeedAfter: {},
  avgDelayBefore: {},
  avgDelayAfter: {},
}

const SpeedDelayGraphic = ({ visData, chapters, chapter }) => {
  const [avgSpeedBefore, avgSpeedAfter, avgDelayBefore, avgDelayAfter] = visData

  const data = useMemo(() => {
    // Step through all chapter keys here.
    // If there is no data for this chapter, we should be using last value.
    const extractValues = obj => {
      let lastValue = 'N/A'
      return chapters.reduce((memo, key) => {
        if (obj.scenario[key]) {
          memo[key] = obj.scenario[key].value
          lastValue = obj.scenario[key].value
        } else {
          memo[key] = lastValue
        }

        return memo
      }, {})
    }

    return {
      avgSpeedBefore: extractValues(avgSpeedBefore),
      avgSpeedAfter: extractValues(avgSpeedAfter),
      avgDelayBefore: extractValues(avgDelayBefore),
      avgDelayAfter: extractValues(avgDelayAfter),
    }
  }, [])

  return (
    <Table>
      <thead>
        <tr>
          <th></th>
          <th>Before RUC</th>
          <th>After RUC</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>Average speed</td>
          <td>{data.avgSpeedBefore[chapter]} mph</td>
          <td>{data.avgSpeedAfter[chapter]} mph</td>
        </tr>
        <tr>
          <td>Average delay</td>
          <td>{data.avgDelayBefore[chapter]} min</td>
          <td>{data.avgDelayAfter[chapter]} min</td>
        </tr>
      </tbody>
    </Table>
  )
}

export default SpeedDelayGraphic
