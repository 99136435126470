import React from 'react'
import styled from '@emotion/styled'

import img from './keyboard.svg'

const Keyboard = styled.img`
  height: 45px;
`
const KeyboardSvg = () => <Keyboard src={img} />

export default KeyboardSvg
