import React from 'react'

const Svg32 = props => (
  <svg width={28} height={17} {...props}>
    <g fill="none" fillRule="evenodd">
      <path
        fill="#F99545"
        d="M14 17C6.268 17 0 10.732 0 3V0h28v3c0 7.732-6.268 14-14 14z"
      />
      <text
        fill="#000"
        fontFamily="Montserrat-SemiBold, Montserrat"
        fontSize={10}
        fontWeight={500}
        letterSpacing={-0.229}
        transform="translate(0 -1)"
      >
        <tspan x={7} y={10}>
          {'5%'}
        </tspan>
      </text>
    </g>
  </svg>
)

export default Svg32
