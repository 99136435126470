export const cleanToNum = data => {
  const split = data.split(',')
  return split.map(f => {
    const val = +f.replace(/ /g, '')
    return Math.round(val * 100)
  })
}

export const makeCalcPill = defaultCacheValue => {
  let lastValue = defaultCacheValue

  return (scenario, duration) => {
    const delay =
      (scenario && scenario.order ? scenario.order : 1) * (duration / 2)

    // Determine starting points for paths so we can animate FROM here
    const [lastBottomHeight, lastMiddleHeight, lastTopHeight] = lastValue
    const lastBottomStart = lastTopHeight + lastMiddleHeight
    const lastMiddleStart = lastTopHeight
    const lastTopStart = 1

    const value =
      scenario && scenario.value
        ? cleanToNum(scenario.value)
        : defaultCacheValue
    // Cache this value to use as a starting point in next render
    lastValue = value

    // Determine where paths should end up so we can animate TO here
    const [bottomHeight, middleHeight, topHeight] = value
    const bottomStart = topHeight + middleHeight
    const middleStart = topHeight
    const topStart = 1

    return {
      delay,
      lastBottomHeight,
      lastBottomStart,
      lastMiddleHeight,
      lastMiddleStart,
      lastTopHeight,
      lastTopStart,
      bottomHeight,
      bottomStart,
      middleHeight,
      middleStart,
      topHeight,
      topStart,
    }
  }
}
export const checkNested = (obj, key) => {
  return key.split('.').reduce((o, x) => {
    return typeof o == 'undefined' || o === null ? o : o[x]
  }, obj)
}
