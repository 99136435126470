import React from 'react'

const Svg37 = props => (
  <svg width={186} height={79} {...props}>
    <path
      d="M1.465.009v72a6 6 0 006 6h178"
      stroke="#B77941"
      fill="none"
      fillRule="evenodd"
      strokeLinecap="square"
    />
  </svg>
)

export default Svg37
