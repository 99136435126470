import React from 'react'
import styled from '@emotion/styled'

import img from './Arup logo.svg'

const Logo = styled.img`
  width: 45%;
`
const ArupLogo = () => {
  return <Logo src={img} />
}

export default ArupLogo
