import React from 'react'

const Svg29 = props => (
  <svg width={28} height={61} {...props}>
    <g fill="none" fillRule="evenodd">
      <path fill="#D26F60" d="M0 0h28v61H0z" />
      <text
        fill="#000"
        fontFamily="Montserrat-SemiBold, Montserrat"
        fontSize={10}
        fontWeight={500}
        letterSpacing={-0.229}
      >
        <tspan x={4} y={29}>
          {'80%'}
        </tspan>
      </text>
    </g>
  </svg>
)

export default Svg29
