import React, { useState } from 'react'
import { Global } from '@emotion/core'
import styled from '@emotion/styled/macro'
import css from '@emotion/css/macro'
import { ThemeProvider } from 'emotion-theming'

import Page from '../Page'
import ModalManager from '../ModalManager'
import theme from '../../constants/theme'

const globalStyles = css`
  @import url('https://fonts.googleapis.com/css?family=Montserrat:500,600&display=swap');

  * {
    box-sizing: border-box;
  }

  html,
  body,
  #root {
    min-height: 100%;
    min-height: 100vh;
    height: 100%;
    width: 100%;
    margin: 0;
    padding: 0;
    font-family: 'Montserrat', sans-serif;
    font-size: 16px;
    overflow-x: hidden;
    color: #333;
    background-color: #f8f8f1;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
`
const AppContainer = styled('div')`
  height: 100%;
  min-height: 100vh;
  width: 100%;
  margin: 0;
  padding: 0;
`
const App = () => {
  const [modalState, setModalState] = useState({
    modalType: null,
    options: {
      modalProps: {},
    },
  })

  return (
    <ThemeProvider theme={theme}>
      <Global styles={globalStyles} />

      <AppContainer>
        <Page
          setModalState={setModalState}
          modalIsOpen={modalState.modalType}
        />
      </AppContainer>

      <ModalManager modalState={modalState} setModalState={setModalState} />
    </ThemeProvider>
  )
}

export default App
