import React from 'react'
import ReactDOM from 'react-dom'
import styled from '@emotion/styled'

const Caption = styled.div`
  background-color: white;
  border-radius: 4px;
  padding: 1rem;
  position: absolute;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  top: ${props => props.top}px;
  left: ${props => props.left}px;

  p {
    margin: 0;
  }

  a[role='button'] {
    display: inline-block;
    margin-top: 0.5rem;
    font-size: 0.75rem;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }
`

/*
  PROBLEM
  - on first render, parent svg isn't in dom yet
  - this means we have nothing to target in SVGtoScreen
    - this means we get an error
    - however it's fine on subsequent usage
  - need to get this to render only once svg is in dom
*/

// https://stackoverflow.com/questions/48343436/how-to-convert-svg-element-coordinates-to-screen-coordinates
function SVGtoScreen(svgX, svgY) {
  const svg = document.getElementById('viz')
  if (svg) {
    var p = svg.createSVGPoint()
    p.x = svgX
    p.y = svgY
    return p.matrixTransform(svg.getScreenCTM())
  }

  return { x: 0, y: 0 }
}

const SVGCaption = ({ x, y, children }) => {
  const { x: left, y: top } = SVGtoScreen(x, y)

  return ReactDOM.createPortal(
    <Caption top={top} left={left}>
      {children}
    </Caption>,
    document.body
  )
}

export default SVGCaption
