import React from 'react'
import styled from '@emotion/styled/macro'
import { motion } from 'framer-motion'

import { ModalContent } from '../ModalUtils'
import { ArrowRight } from '../Icons'
import { P } from '../../common/Styles'

import TEXT_CONTENT from '../../constants/textContent'

const Columns = styled.div`
  column-count: 2;

  h3,
  p {
    margin-top: 0;
  }
`

const ViewScenarioLabel = styled.div`
  font-size: 0.875rem;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  cursor: pointer;

  > * + * {
    margin-left: 0.5rem;
  }

  svg {
    display: block;
  }

  p {
    margin: 0;
  }
`

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 2rem;

  h3 {
    margin: 0;
  }

  ${ViewScenarioLabel} {
    margin-left: 1rem;
  }
`

const ScenarioModal = ({ scenario, hideModal }) => (
  <ModalContent>
    <Header>
      <h3>{scenario} scenario</h3>

      <ViewScenarioLabel onClick={hideModal}>
        <p>View scenario</p>
        <motion.div whileHover={{ scale: 1.2 }} whileTap={{ scale: 0.8 }}>
          <ArrowRight height={20} fill={'#333'} />
        </motion.div>
      </ViewScenarioLabel>
    </Header>

    <Columns>
      {TEXT_CONTENT.scenarios[scenario].scenarioText.map((paragraph, i) => (
        <P key={`${scenario}-${i}`}>{paragraph}</P>
      ))}
    </Columns>
  </ModalContent>
)

export default ScenarioModal
