import React from 'react'
import { motion } from 'framer-motion'
import { S_DURATION, DELAY } from '../constants/config'

export default ({
  value,
  lastValue = 0,
  prefix = null,
  suffix = null,
  order,
  ...rest
}) => {
  const delay = order * DELAY
  if (value === lastValue) {
    return (
      <tspan {...rest}>
        {prefix}
        {value}
        {suffix}
      </tspan>
    )
  }
  return (
    <motion.tspan
      {...rest}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: S_DURATION, delay }}
    >
      {prefix}
      {value}
      {suffix}
    </motion.tspan>
  )
}
