export default {
  /* Primary colours */
  slate: '#353535',
  grey: '#E0E0E0',
  darkgrey: '#666666',
  lightgrey: '#b3b3b3',
  lightgrey2: '#D8D8D8',
  lightgrey3: '#F7F8F8',
  /* Secondary colours */
  light_blue: '#a9ddf3',
  dark_blue: '#0f5573',
  /* Tertiary colours */
  orange: '#f99545',
  blue: '#28aae1',
  purple: '#696eb4',
  green: '#24cc86',
  red: '#f05023',
  congestionRed: '#c15727',
  /* others */
  white: '#ffffff',
  black: '#333333',
}
