import React from 'react'
import styled from '@emotion/styled'

import { P } from '../../common/Styles'

const Card = styled.div``

const Icon = styled.img`
  height: 45px;
  width: 45px;
  border-radius: 5px;
  background-color: ${props => props.theme.green};
`

const Title = styled.h3`
  font-size: 0.875rem;
  margin: 4px 0 3px;
`

const IconCard = ({ img, title, subtitle, body }) => (
  <Card>
    <Icon src={img} />
    <Title>{title}</Title>
    <P>{body}</P>
  </Card>
)

export default IconCard
